import { createContext, useContext } from "react";
import { FormErrors, FormTouched } from "./types";

export interface FormContextProps<TValues> {
  values: TValues;
  errors: FormErrors<TValues>;
  touched: FormTouched<TValues>;
  handleChange: (field: keyof TValues, value: any) => void;
  handleBlur: (field: keyof TValues) => void;
  handleSubmit: () => void;
  resetForm: () => void;
  setValues: (values: TValues) => void;
}

export const FormContext = createContext<FormContextProps<any> | undefined>(
  undefined,
);

export const useForm = <TValues extends Record<string, any>>() => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context as FormContextProps<TValues>;
};
