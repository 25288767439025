import type { AlgoliaPublication } from "@10xdev/types/models/Algolia/Publication";
import { css } from "@emotion/react";
import { format } from "date-fns";
import { startCase } from "lodash-es";
import { compact } from "lodash-es";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Mark from "../Connectors/Mark";
import Breadcrumb from "./Breadcrumb";

interface Props {
  hit: Hit<AlgoliaPublication>;
}

const Publication: FunctionComponent<Props> = ({ hit }) => {
  const date = hit.date ? format(new Date(hit.date), "MMM yyyy") : undefined;
  const journal = startCase(hit.journal);
  const publicationBreadcrumb = compact(["Publications", journal, date]);

  return (
    <Anchor
      css={css`
        display: flex;
      `}
      href={{
        pathname: "resources/publications",

        query: { query: hit.pmid, pmid: hit.pmid },
      }}
    >
      <div>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
        <Breadcrumb path={publicationBreadcrumb} />
      </div>
    </Anchor>
  );
};

export default Publication;
