import { colorGrayLightest, mediaDesktop } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useState } from "react";
import type { SearchState } from "react-instantsearch-core";

import Anchor from "../Anchor";
import { buttonCss } from "../Button";
import Grid from "../Grid";
import Icon from "../Icon";
import QueryInput from "../Search/QueryInput";
import Text from "../Text";
import { INDICES } from "./constants";
import Filter from "./Filter";
import IndexHits from "./IndexHits";

interface Props {
  onHits: () => void;
  searchState: SearchState;
}

const SearchLayout: FunctionComponent<Props> = ({ onHits, searchState }) => {
  const [filter, setFilter] = useState({ id: "all" });

  return (
    <>
      <div
        css={css`
          background: ${colorGrayLightest};
          padding: 2rem 1rem;
          @media (min-width: ${mediaDesktop}) {
            padding-bottom: 3rem;
          }
        `}
      >
        <Grid
          css={css`
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
          `}
          gap={{ base: "1rem", desktop: "2rem" }}
        >
          <QueryInput
            activeWidth={"100%"}
            inactiveWidth={"100%"}
            placeholder={"Search..."}
            style={{ marginBottom: 0 }}
          />
          <Grid
            alignItems={{ base: "start", tabletLandscape: "center" }}
            gap={"1.5rem 2rem"}
            gridTemplateColumns={{ base: "1fr", desktop: "1fr 260px" }}
          >
            <Filter active={filter} onClick={setFilter} />
            <Anchor
              css={[
                buttonCss({
                  background: "white",
                  border: true,
                  color: "blue",
                }),
                css`
                  height: 40px;
                  width: 260px;
                `,
              ]}
              href={"/support/search"}
            >
              <Icon
                color={"inherit"}
                size={"13px"}
                source={"external-link"}
                yPos={"1px"}
              />
              <Text
                as={"span"}
                color={"inherit"}
                size={"small"}
                weight={"medium"}
              >
                {"Search documentation only"}
              </Text>
            </Anchor>
          </Grid>
        </Grid>
      </div>

      <div
        css={css`
          box-sizing: border-box;
          display: grid;
          margin: 0 auto;
          max-width: 1200px;
          padding: 3rem 1rem 6rem;
          row-gap: 6rem;
          width: 100%;
          @media (min-width: ${mediaDesktop}) {
            padding-left: 0;
            padding-right: 0;
          }
        `}
      >
        {filter.id === "all"
          ? INDICES.map((index) => {
              return (
                <IndexHits
                  active={filter}
                  expanded={filter.id === index.id}
                  index={index}
                  key={index.id}
                  onHits={onHits}
                  searchState={searchState}
                />
              );
            })
          : INDICES.filter((index) => index.id === filter.id).map((index) => {
              return (
                <IndexHits
                  active={filter}
                  expanded={filter.id === index.id}
                  index={index}
                  key={index.id}
                  onHits={onHits}
                  searchState={searchState}
                />
              );
            })}
      </div>
    </>
  );
};

export default SearchLayout;
