export const SERVICE_PROVIDER_FACETS = [
  {
    attribute: "products.name",
    label: "Services",
    order: [
      "Universal 3' Gene Expression",
      "Universal 5' Gene Expression",
      "Flex Gene Expression",
      "Epi ATAC",
      "Epi Multiome ATAC + Gene Expression",
      "Spatial Gene Expression",
      "CytAssist Spatial Gene Expression",
      "CytAssist Spatial Gene and Protein Expression",
      "HD Spatial Gene Expression",
      "In Situ Gene Expression",
    ],
  },
  {
    attribute: "regions.label",
    label: "Regions",
  },
  {
    attribute: "serviceTypes",
    label: "Organization Type",
  },
];
