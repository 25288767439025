import type { AlgoliaDocument } from "@10xdev/types/models/Algolia/Document";
import { documentsIndexName } from "@10xdev/utils";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Alert from "../Alert";
import Anchor from "../Anchor";
import Search, { InfiniteHits } from "../Search";
import Filters from "../Search/Filters";
import { DOCUMENT_FACETS } from "./constants";
import DocumentCard from "./DocumentCard";

const DocumentSearch: FunctionComponent = () => {
  return (
    <div
      className={"DocumentSearch"}
      css={css`
        display: flex;
        flex-direction: column;
        gap: 32px;
      `}
    >
      <Alert>
        {"Visit our "}
        <Anchor href={"/support"} target={"_blank"}>
          {"support site"}
        </Anchor>{" "}
        {
          "to view support documents, e.g. User Guides, Demonstrated Protocols, Tech Notes."
        }
      </Alert>
      <Search
        hitsRenderer={({ onHits }) => {
          return (
            <InfiniteHits
              hitRenderer={({ hit }: { hit: AlgoliaDocument }) => {
                return <DocumentCard document={hit} />;
              }}
              onHits={onHits}
            />
          );
        }}
        includeStats={true}
        indexName={documentsIndexName.toString()}
        placeholder={"Search documents"}
        sidebar={<Filters facets={DOCUMENT_FACETS} title={"Documents"} />}
      />
    </div>
  );
};

export default DocumentSearch;
