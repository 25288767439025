import { colorRedLight, colorRed } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

type Props = {
  disabled?: boolean;
};

const RequiredAsterisk = ({ disabled = false }: Props) => {
  return (
    <span
      css={css`
        display: inline-block;
        color: ${disabled ? colorRedLight : colorRed};
        margin-left: 0.5rem;
      `}
    >
      *
    </span>
  );
};

export default RequiredAsterisk;
