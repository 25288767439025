import { colorBlueDark } from "@10xdev/design-tokens";
import type { AlgoliaSupportDocument } from "@10xdev/types/models/Algolia/SupportDocument";
import { css } from "@emotion/react";
import { forwardRef, useState } from "react";

import Icon from "../../Icon";
import Text from "../../Text";
import { HEIGHT as TOP_BAR_HEIGHT } from "../TopBar";
import GroupedDocuments from "./GroupedDocuments";
import {
  getContractButtonText,
  getExpandButtonText,
  getPluralizedDocType,
  pluckDocuments,
} from "./utils";

interface Props {
  description?: string;
  docType: string;
  documents: AlgoliaSupportDocument[];
  generateHref: (document: AlgoliaSupportDocument) => string;
  id?: string;
}

export const INITIAL_ITEMS_SHOWN = 6;

const DocumentsSectionByDocType = forwardRef<HTMLDivElement, Props>(
  ({ id, description, documents, docType, generateHref }: Props, ref) => {
    const isExpandable = documents.length > INITIAL_ITEMS_SHOWN;
    const [isExpanded, setIsExpanded] = useState(true);

    const {
      visibleGroupedDocuments,
      visibleUngroupedDocuments,
      visibleDocumentsCount,
    } = pluckDocuments(documents, isExpanded);

    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          scroll-margin-top: ${TOP_BAR_HEIGHT};
        `}
        id={id}
        ref={ref}
      >
        <header
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Text
            as={"h3"}
            color={"steelDarkest"}
            size={"large"}
            weight={"semibold"}
          >
            {getPluralizedDocType(docType)}{" "}
            <Text
              as={"span"}
              color={"steelMedium"}
              size={"large"}
              weight={"medium"}
            >
              {`(${documents.length})`}
            </Text>
          </Text>
          {description && (
            <Text
              as={"p"}
              color={"steelDarker"}
              size={"small"}
              weight={"regular"}
            >
              {description}
            </Text>
          )}
        </header>
        <GroupedDocuments
          generateHref={generateHref}
          groupedDocuments={visibleGroupedDocuments}
          ungroupedDocuments={visibleUngroupedDocuments}
        />
        {isExpandable && (
          <Text
            as={"button"}
            color={"blueMedium"}
            css={css`
              align-items: baseline;
              display: flex;
              gap: 12px;
              background: none;
              border: none;

              &:hover {
                cursor: pointer;
                color: ${colorBlueDark};
              }
            `}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            size={"medium"}
            weight={"medium"}
          >
            {isExpanded ? (
              <>
                {getContractButtonText(docType)}
                <Icon size={"12px"} source={"nav-up"} />
              </>
            ) : (
              <>
                {getExpandButtonText(
                  docType,
                  documents.length,
                  visibleDocumentsCount,
                )}
                <Icon size={"12px"} source={"nav-down"} />
              </>
            )}
          </Text>
        )}
      </div>
    );
  },
);

DocumentsSectionByDocType.displayName = "DocumentsSectionByDocType";

export default DocumentsSectionByDocType;
