import { newsArticlesIndexName } from "@10xdev/utils";
import { useRouter } from "next/router";
import { useState } from "react";
import { InstantSearch } from "react-instantsearch-dom";

import { getHref } from "../../utils";
import NewsArticleHits from "./NewsArticleHits";
import algoliaClient from "../../utils/algolia/searchOnlyClient";

export default function News() {
  const [searchState, setSearchState] = useState({});
  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const router = useRouter();
  const handleSearchStateChange = (updatedSearchState: any) => {
    if (debounce) {
      clearTimeout(debounce);
    }

    // Update router
    const href = getHref(router.pathname, updatedSearchState);
    setDebounce(
      setTimeout(() => {
        router.push(href, href, { shallow: true });
      }, 400),
    );

    // Update searchState
    setSearchState(updatedSearchState);
  };

  if (!newsArticlesIndexName) {
    throw new Error("No news article index name present");
  }

  return (
    <InstantSearch
      indexName={newsArticlesIndexName.toString()}
      onSearchStateChange={handleSearchStateChange}
      searchClient={algoliaClient()}
      searchState={searchState}
    >
      <NewsArticleHits />
    </InstantSearch>
  );
}
