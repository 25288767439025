import type { Person } from "@10xdev/types/models/Person";
import { format } from "date-fns";

import type { IconSource } from "@10xdev/types/models/IconSource";
import type { AnalysisGuideType } from "./types";

export const getCitation = (authors: Person[], timestamp: string) => {
  const date = timestamp ? format(new Date(timestamp), "MMMM d, yyyy") : "";

  // Multiple authors not supported yet
  const { foreName, lastName } = authors[0];
  const name = `${foreName} ${lastName}`;
  return `${name} ${name && date ? "on" : ""} ${date || ""}`;
};

export const getTypeIcon = (type: AnalysisGuideType) => {
  const guideTypeIcon: Record<AnalysisGuideType, IconSource> = {
    ["Blog Post"]: "comment",
    ["Introduction"]: "lightbulb-small",
    ["Tutorial"]: "graduation-cap",
    ["Workshop"]: "comment",
  };

  return guideTypeIcon[type];
};
