import {
  colorSteelLight,
  colorGrayLightest,
  fontSizeXxsmall,
  colorSteelDarkest,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

interface TagLabelProps {
  text: string;
}

const TagLabel = ({ text }: TagLabelProps) => {
  return (
    <div
      css={css`
        display: inline-block;
        padding: 0.2rem 0.6rem;
        border: 1px solid ${colorSteelLight};
        border-radius: 0.5rem;
        background-color: ${colorGrayLightest};
        font-family: "Roboto Mono", monospace;
        font-size: ${fontSizeXxsmall};
        color: ${colorSteelDarkest};
        width: auto;
      `}
    >
      {text}
    </div>
  );
};

export default TagLabel;
