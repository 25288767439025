import Grid from "../Grid";
import type { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const FormRow = ({ children, className }: Props) => {
  return (
    <Grid
      alignItems="center"
      className={className}
      gap={{ base: "1rem", tabletPortrait: "2rem" }}
      gridTemplateColumns={{ base: "1fr", tabletPortrait: "140px 1fr" }}
    >
      {children}
    </Grid>
  );
};

export default FormRow;
