import { FormikConfig, useFormik } from "formik";
import { useEffect } from "react";
import { FormContext, FormContextProps } from "./FormContext";
import { FormErrors, FormTouched } from "./types";

interface FormikFormProviderProps<TValues>
  extends Omit<FormikConfig<TValues>, "validationSchema"> {
  validationSchema: any;
  children: React.ReactNode;
  onChange?: (values: TValues) => void;
}

export const FormikFormProvider = <TValues extends Record<string, any>>({
  initialValues,
  validationSchema,
  onChange,
  onSubmit,
  children,
}: FormikFormProviderProps<TValues>) => {
  const formik = useFormik<TValues>({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (onChange) {
      onChange(formik.values);
    }
  }, [formik.values, onChange]);

  const formContextValue: FormContextProps<TValues> = {
    values: formik.values,
    errors: formik.errors as FormErrors<TValues>,
    touched: formik.touched as FormTouched<TValues>,
    handleChange: (field, value) => {
      formik.setFieldValue(field as string, value);
    },
    handleBlur: (field) => {
      formik.setFieldTouched(field as string, true);
    },
    handleSubmit: formik.handleSubmit,
    resetForm: formik.resetForm,
    setValues: formik.setValues,
  };

  return (
    <FormContext.Provider value={formContextValue}>
      {children}
    </FormContext.Provider>
  );
};
