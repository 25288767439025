import type { Distributor as DistributorInterface } from "@10xdev/types/models/Distributor";

export default function groupDistributorsByRegion(
  distributors: DistributorInterface[],
) {
  return distributors.reduce<Record<string, DistributorInterface[]>>(
    (grouping, distributor) => {
      const previousRegionArray = grouping[distributor.region] || [];
      return {
        ...grouping,
        [distributor.region]: [...previousRegionArray, distributor],
      };
    },
    {},
  );
}
