import { css } from "@emotion/react";
import {
  borderRadiusSmall,
  colorBlueLight,
  colorBlueMedium,
  colorSteelLight,
  colorWhite,
} from "@10xdev/design-tokens";
import Icon from "../Icon";
import {
  Checkbox as HeadlessUICheckbox,
  CheckboxProps as HeadlessUICheckboxProps,
} from "@headlessui/react";

interface CheckboxProps extends HeadlessUICheckboxProps {
  className?: string;
}

const Checkbox = ({ className, ...rest }: CheckboxProps) => {
  return (
    <HeadlessUICheckbox
      css={css`
        position: relative;
        display: inline-flex;
        :focus {
          outline: none;
        }

        :focus-visible {
          outline: 1px solid ${colorBlueMedium};
        }
      `}
      {...rest}
    >
      {({ checked, disabled }) => (
        <div
          className={className}
          css={css`
            cursor: ${disabled ? "not-allowed" : "pointer"};
            width: 1rem;
            height: 1rem;
            border: 1px solid ${colorSteelLight};
            border-radius: ${borderRadiusSmall};
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: ${colorWhite};
            transition: border-color 0.2s, background-color 0.2s;
            ${checked &&
            `
                background-color: ${
                  disabled ? colorBlueLight : colorBlueMedium
                };
                border-color: ${disabled ? colorBlueLight : colorBlueMedium};
              `}

            &:hover {
              border-color: ${!disabled && colorBlueMedium};
            }
          `}
        >
          {checked ? (
            <Icon color="white" size="10px" source="checkmark" />
          ) : null}
        </div>
      )}
    </HeadlessUICheckbox>
  );
};

export default Checkbox;
