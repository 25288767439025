import { Facets } from "@10xdev/types/models/SupportDocument";
import type { AlgoliaSupportDocument } from "@10xdev/types/models/Algolia/SupportDocument";
import { groupBy, sum } from "lodash-es";
import pluralize from "pluralize";
import type { Refinement } from "react-instantsearch-core";

import type { Step } from "../Documentation/types";
import { DOCUMENT_TYPE_SORT_ORDER } from "./constants";
import { INITIAL_ITEMS_SHOWN } from "./DocumentsSectionByDocType";

export function pluckDocuments(
  documents: AlgoliaSupportDocument[],
  isExpanded: boolean,
) {
  const ungroupedDocuments = documents.filter((document) => !document.group);
  const groupedDocuments = groupBy(
    documents.filter((document) => !!document.group),
    "group",
  );

  const visibleUngroupedDocuments = getVisibleUngroupedDocuments(
    ungroupedDocuments,
    isExpanded,
  );

  const groupedDocumentsCountNeeded =
    INITIAL_ITEMS_SHOWN - visibleUngroupedDocuments.length;

  const visibleGroupedDocuments = getVisibleGroupedDocuments(
    groupedDocuments,
    groupedDocumentsCountNeeded,
    isExpanded,
  );

  const visibleDocumentsCount =
    visibleUngroupedDocuments.length +
    sum(visibleGroupedDocuments.map(({ docs }) => docs.length));

  return {
    visibleDocumentsCount,
    visibleGroupedDocuments,
    visibleUngroupedDocuments,
  };
}

export function getVisibleUngroupedDocuments(
  ungroupedDocuments: AlgoliaSupportDocument[],
  isExpanded: boolean,
) {
  if (isExpanded) {
    return ungroupedDocuments;
  }

  return ungroupedDocuments.slice(0, INITIAL_ITEMS_SHOWN);
}

type GroupedDocuments = {
  docs: AlgoliaSupportDocument[];
  groupName: string;
};

export function getVisibleGroupedDocuments(
  groupedDocuments: Record<string, AlgoliaSupportDocument[]>,
  groupedDocumentsCountNeeded: number,
  isExpanded: boolean,
): GroupedDocuments[] {
  // sort by document group in reverse alphabetical order
  const sortedGroups = Object.entries(groupedDocuments).sort(
    ([groupA], [groupB]) => (groupA > groupB ? -1 : 1),
  );

  if (isExpanded) {
    return sortedGroups.map(([group, documents]) => {
      return {
        docs: documents,
        groupName: group,
      };
    });
  }

  return sortedGroups.reduce<GroupedDocuments[]>(
    (memo, [groupName, documentsOfGroup]) => {
      const currentLength = sum(memo.map(({ docs }) => docs.length));

      if (currentLength >= groupedDocumentsCountNeeded) {
        return memo;
      }

      return [
        ...memo,
        {
          docs: documentsOfGroup.slice(
            0,
            groupedDocumentsCountNeeded - currentLength,
          ),
          groupName,
        },
      ];
    },
    [],
  );
}

export const getPluralizedDocType = (docType: string) => {
  const irregularPlurals = [
    "Getting Started",
    "Instrument Software",
    "Software",
    "Xenium v1 Panel Information",
    "Introduction",
    "Xenium Prime Panel Information",
    "Panel Design Tool",
  ];
  return irregularPlurals.includes(docType) ? docType : pluralize(docType);
};

export function getContractButtonText(docType: string) {
  return `See fewer ${getPluralizedDocType(docType)}`;
}

export function getExpandButtonText(
  docType: string,
  documentsLength: number,
  visibleDocumentsCount: number,
) {
  const hiddenDocumentsCount = documentsLength - visibleDocumentsCount;

  return `See all ${getPluralizedDocType(
    docType,
  )} (${hiddenDocumentsCount} more)`;
}

export const getProductSlugFromRefinement = (items: Refinement[]) => {
  return items.find((item) => {
    return item.attribute === Facets.ProductSlug;
  })?.currentRefinement;
};

export const getStepFromProductSlug = (
  document: AlgoliaSupportDocument,
  productSlug?: string,
) => {
  return productSlug
    ? document.steps?.find((step) => step.productSlug === productSlug)
    : document.steps?.[0];
};

export const getCompatibilityTableHref = ({
  documentSlug,
  step,
  supportProductPageSlug,
}: {
  documentSlug: string;
  step: { slug: string; title?: string };
  supportProductPageSlug: string;
}) => {
  return `/support/${supportProductPageSlug}/documentation/steps/${step.slug}/compatibility/${documentSlug}`;
};

export const getMatchingDocumentationStep = (
  documentationSteps: Step[] | null,
  stepSlug: string,
) => {
  return (documentationSteps || []).find(
    (documentationStep) => documentationStep.slug === stepSlug,
  );
};

export const documentSortFn = (itemA: string, itemB: string): number => {
  const aIndex = DOCUMENT_TYPE_SORT_ORDER.indexOf(itemA);
  const bIndex = DOCUMENT_TYPE_SORT_ORDER.indexOf(itemB);
  // If neither document type exists, sort alphabetically
  if (aIndex === -1 && bIndex === -1) {
    return itemA.localeCompare(itemB);
  }
  // If A doesn't exist, sort B first
  if (aIndex === -1) {
    return 1;
  }
  // If B doesn't exist, sort A first
  if (bIndex === -1) {
    return -1;
  }
  // Otherwise, sort by index
  return aIndex - bIndex;
};
