import type { IconSource } from "@10xdev/types/models/IconSource";
import { css } from "@emotion/react";

import { TextSize } from "@10xdev/types";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";

interface Props {
  className?: string;
  label: string;
  icon?: IconSource;
  url: string;
  color?: string;
  size?: TextSize;
}

const Link = ({ className, label, icon, url, size = "medium" }: Props) => {
  return (
    <Anchor
      className={className}
      css={css`
        align-items: center;
        display: flex;
        gap: 0.75rem;
      `}
      href={url}
    >
      <Text
        as={"span"}
        color={"inherit"}
        responsive={true}
        size={size}
        weight={"semibold"}
      >
        {label}
        {icon && (
          <Icon
            color={"inherit"}
            css={css`
              flex-shrink: 0;
              margin-left: 0.75rem;
            `}
            size={"11.5px"}
            source={icon}
            yPos={"2px"}
          />
        )}
      </Text>
    </Anchor>
  );
};

export default Link;
