import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  item: {
    description?: string;
    footnote?: string;
    link?: LinkItem;
    title?: string;
  };
}

const ListItem: FunctionComponent<Props> = ({ item }) => {
  const { description, footnote, link, title } = item;
  const { icon, label, type, url } = link || {};

  return (
    <li
      css={css`
        padding: 1.5rem 2rem;
      `}
    >
      {title ? (
        <Text
          as={"div"}
          css={css`
            margin-bottom: 1rem;
          `}
          responsive={true}
          size={"large"}
          weight={"semibold"}
        >
          {title}
        </Text>
      ) : null}

      {description ? (
        <Text
          as={"div"}
          css={css`
            margin-bottom: 1rem;
          `}
          responsive={true}
          size={title ? "small" : "medium"}
        >
          {description}
        </Text>
      ) : null}

      <Text
        as={"div"}
        color={"blue"}
        css={css`
          margin-bottom: ${footnote ? ".5rem" : 0};
        `}
        responsive={true}
        size={"small"}
        weight={"semibold"}
      >
        {url ? (
          <Anchor aria-label={`${title}: ${label}`} href={url} target={"_self"}>
            {icon ? (
              <Icon
                css={{ marginRight: ".5rem" }}
                size={"13px"}
                source={icon}
                yPos={"2px"}
              />
            ) : null}
            {label}
            {type ? (
              <Icon
                css={{ marginLeft: ".5rem" }}
                size={"13px"}
                source={type}
                yPos={"1px"}
              />
            ) : null}
          </Anchor>
        ) : null}
      </Text>

      {footnote ? (
        <Text
          as={"div"}
          color={"midgray"}
          css={css`
            margin-bottom: 0.25rem;
          `}
          size={"xsmall"}
        >
          {footnote}
        </Text>
      ) : null}
    </li>
  );
};

export default ListItem;
