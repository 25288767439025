import type { BlogPost } from "@10xdev/types/models/BlogPost";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Image from "../Image";
import Text from "../Text";

interface Props {
  author: BlogPost["author"];
  className?: string;
}

const Author: FunctionComponent<Props> = ({ author, className }) => {
  return (
    <div
      className={"Author"}
      css={css`
        display: flex;
        align-items: center;
        margin-top: auto;
      `}
    >
      <Image
        alt={""}
        css={css`
          border-radius: 50%;
          margin-right: 0.75rem;
        `}
        src={
          author.avatar ||
          "https://cdn.10xgenomics.com/image/upload/v1623190708/people/default.png"
        }
        width={"48px"}
      />
      <Text
        as={"div"}
        className={className}
        color={"steelDarker"}
        size={"medium"}
        weight={"regular"}
      >
        {`${author.foreName} ${author.lastName}`}
      </Text>
    </div>
  );
};

export default Author;
