import type { IconSource } from "@10xdev/types/models/IconSource";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Card from "../Card";
import Heading from "../Heading";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";
import { getCardTextSizes } from "./utils";

interface Props {
  description?: string;
  image?: {
    alt?: string;
    url: string;
  };
  link: {
    label: string;
    type?: IconSource;
    url: string;
  };
  minHeight?: string | null;
  textSize?: "large" | "medium";
  title?: string;
}

const ContentCard: FunctionComponent<Props> = ({
  description,
  image,
  link,
  minHeight,
  textSize = "medium",
  title,
}) => {
  const sizes = getCardTextSizes();

  return (
    <li
      css={css`
        list-style: none;
      `}
    >
      <Anchor href={link.url}>
        <Card
          css={css`
            ${minHeight ? `min-height: ${minHeight}` : ""};
            padding: 3rem;
          `}
          elevate={true}
          hoverable={true}
          outline={true}
        >
          {image?.url ? (
            <div
              css={css`
                margin-bottom: 3rem;
                min-height: 1px;
              `}
            >
              <Image alt={image.alt} src={image.url}></Image>
            </div>
          ) : null}
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: ${sizes[textSize].headingMargin};
            `}
            responsive={true}
            size={sizes[textSize].headingSize}
          >
            {title}
          </Heading>
          {description ? (
            <Text
              as={"div"}
              color={"midgray"}
              css={css`
                margin-bottom: ${link.label ? "2rem" : 0};
              `}
              responsive={true}
              size={sizes[textSize].descriptionSize}
            >
              {description}
            </Text>
          ) : null}
          {link.label ? (
            <Text
              as={"div"}
              color={"blue"}
              css={css`
                margin-top: auto;
              `}
              size={"small"}
              weight={"medium"}
            >
              {link.label}
              {link.type ? (
                <Icon
                  size={"11px"}
                  source={link.type}
                  xPos={"16px"}
                  yPos={"1px"}
                />
              ) : null}
            </Text>
          ) : null}
        </Card>
      </Anchor>
    </li>
  );
};

export default ContentCard;
