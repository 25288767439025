import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";
import { useState } from "react";
import type { SearchState } from "react-instantsearch-core";
import { InstantSearch } from "react-instantsearch-dom";

import { useUrlSync } from "../Search/hooks";
import searchClient from "../../utils/algolia/searchOnlyClient";

type HitsRenderer = (args: {
  onHits: () => void;
  searchState: SearchState;
}) => ReactNode;

interface Props {
  hitsRenderer: HitsRenderer;
  indexName: string;
}

const Search: FunctionComponent<Props> = ({ hitsRenderer, indexName }) => {
  const { handleSearchStateChange, searchState } = useUrlSync();

  const [isVisible, setIsVisible] = useState(false);
  const handleHits = () => setIsVisible(true);

  return (
    <InstantSearch
      indexName={indexName}
      onSearchStateChange={handleSearchStateChange}
      searchClient={searchClient()}
      searchState={searchState}
    >
      <section
        css={css`
          min-height: 800px;
        `}
      >
        <div
          css={css`
            display: ${isVisible ? "block" : "none"};
          `}
        >
          {hitsRenderer({ onHits: handleHits, searchState })}
        </div>
      </section>
    </InstantSearch>
  );
};

export default Search;
