import {
  borderRadiusMedium,
  colorBlueDark,
  colorBlueMedium,
} from "@10xdev/design-tokens";
import type { IconSource } from "@10xdev/types/models/IconSource";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";
import { useDownloadURL, useLoupeAutoDownload } from "./hooks";

interface Props {
  className?: string;
  eula?: boolean;
  icon: IconSource;
  softwareSlug: string;
  title: string;
  url: string;
}

const DownloadButton = ({
  className,
  eula,
  icon,
  title,
  url,
  softwareSlug,
}: Props) => {
  const { ref } = useLoupeAutoDownload();
  const { href, softwareEULA } = useDownloadURL(url, softwareSlug);

  return (
    <Anchor
      className={className}
      css={css`
        align-items: center;
        background: ${colorBlueMedium};
        border-radius: ${borderRadiusMedium};
        box-sizing: border-box;
        display: flex;
        flex-shrink: 0;
        gap: 0.5rem;
        height: 56px;
        justify-content: center;
        width: 256px;

        :hover {
          background: ${colorBlueDark};
        }
      `}
      download={eula ? softwareEULA : true}
      href={eula ? href : url}
      ref={ref}
    >
      <Icon color={"white"} size={"15px"} source={icon as IconSource} />
      <Text as={"span"} color={"white"} size={"medium"} weight={"semibold"}>
        {title}
      </Text>
    </Anchor>
  );
};

export default DownloadButton;
