import type { NewsArticle as NewsArticleInterface } from "@10xdev/types/models/NewsArticle";

import Anchor from "../Anchor";
import Heading from "../Heading";
import Icon from "../Icon";
import Text from "../Text";
import ThematicBreak from "../ThematicBreak";

export default function NewsArticle({
  newsArticle,
}: {
  newsArticle: NewsArticleInterface;
}) {
  return (
    <>
      <Heading as={"h2"} color={"blue"} size={"xlarge"} weight={"medium"}>
        <Anchor
          css={{
            textDecoration: "none",
          }}
          href={newsArticle.url}
          target={"_blank"}
        >
          {newsArticle.title}
        </Anchor>
      </Heading>

      <Text as={"p"} size={"medium"} weight={"regular"}>
        {newsArticle.publisher}
        <Anchor
          aria-label={`Read ${newsArticle.title} article`}
          href={newsArticle.url}
          target={"_blank"}
        >
          <Icon
            color={"gray"}
            css={{
              paddingLeft: "12px",
              verticalAlign: "middle",
            }}
            size={"14px"}
            source={"external-link"}
          />
        </Anchor>
      </Text>

      <ThematicBreak />
    </>
  );
}
