import { GetSupportDocumentsQuery } from "../__generated__/graphql-types";
import { Step } from "./Step";

export enum Facets {
  AdditionalApplicationSlug = "additionalApplications.slug",
  CGNumber = "attachments.cgNumber",
  CompatibilityChemistryVersion = "compatibility.chemistryVersion",
  Description = "description",
  DocType = "docType",
  InstrumentSlug = "instruments.slug",
  IsAssayDocument = "isAssayDocument",
  IsCompatibilityTable = "isCompatibilityTable",
  ProductSlug = "steps.productSlug",
  Slug = "slug",
  StepSlug = "steps.slug",
  Title = "title",
  WorkflowSlug = "steps.workflowSlug",
}

export type SupportDocument = Omit<
  GetSupportDocumentsQuery["supportDocuments"][number],
  "steps"
> & { steps: Step[] };
