import {
  layoutWidth1200,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Heading from "../Heading";
import Text from "../Text";

interface Props {
  achievements: {
    description: string;
    metric: string;
    title: string;
  }[];
  sectionTitle?: string;
}

const Achievements: FunctionComponent<Props> = ({
  sectionTitle,
  achievements,
}) => {
  return (
    <div
      css={css`
        max-width: ${layoutWidth1200};
        width: 100%;

        @media (min-width: ${mediaTabletPortrait}) {
          column-gap: 2rem;
          display: flex;
          justify-content: space-between;
        }
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          flex: 1;
        `}
        size={"xxlarge"}
      >
        {sectionTitle}
      </Heading>

      {achievements ? (
        <ul
          css={css`
            display: grid;
            flex: 1;
            grid-template-columns: 1fr;
            column-gap: 2rem;
            list-style: none;
            margin: 0;
            padding: 0;
            @media (min-width: ${mediaTabletLandscape}) {
              row-gap: 3.5rem;
              grid-template-columns: 1fr 1fr;
            }
          `}
        >
          {achievements?.map((achievement) => {
            const { description, metric } = achievement;
            return (
              <li
                css={css`
                  margin: 0 0 1.5rem;
                  width: 100%;
                `}
                key={metric}
              >
                <Heading
                  as={"h4"}
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                  size={"xlarge"}
                >
                  {metric}
                </Heading>
                <Text as={"div"} size={"medium"}>
                  {description}
                </Text>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default Achievements;
