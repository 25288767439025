import { css } from "@emotion/react";
import {
  colorBlueLight,
  colorBlueMedium,
  colorSteelLight,
  colorWhite,
} from "@10xdev/design-tokens";
import {
  Radio as HeadlessUIRadio,
  RadioProps as HeadlessUIRadioProps,
} from "@headlessui/react";

interface RadioProps extends HeadlessUIRadioProps {
  className?: string;
}

const Radio = ({ className, ...rest }: RadioProps) => {
  return (
    <HeadlessUIRadio
      css={css`
        position: relative;
        display: inline-flex;
        :focus {
          outline: none;
        }

        :focus-visible {
          outline: 1px solid ${colorBlueMedium};
        }
      `}
      {...rest}
    >
      {({ checked, disabled }) => (
        <div
          className={className}
          css={css`
            cursor: ${disabled ? "not-allowed" : "pointer"};
            width: 1rem;
            height: 1rem;
            border: 1px solid ${colorSteelLight};
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: ${colorWhite};
            transition: border-color 0.2s;
            ${checked &&
            `
                border-color: ${disabled ? colorBlueLight : colorBlueMedium};
              `}

            &:hover {
              border-color: ${!disabled && colorBlueMedium};
            }
          `}
        >
          {checked ? (
            <div
              css={css`
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 50%;
                background-color: ${disabled
                  ? colorBlueLight
                  : colorBlueMedium};
                transition: background-color 0.2s;
              `}
            />
          ) : null}
        </div>
      )}
    </HeadlessUIRadio>
  );
};

export default Radio;
