import { mediaPhoneOnly, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef } from "react";

import DownloadLinkList from "../../SoftwareDownload/DownloadCard/DownloadLinkList";
import NavLink from "../../SoftwareDownload/DownloadCard/NavLink";
import type {
  DownloadLink,
  Link,
} from "../../SoftwareDownload/DownloadCard/types";
import Text from "../../Text";
import DownloadHighlights from "./DownloadHighlights";
import Summary from "./Summary";

export interface Props {
  className?: string;
  downloadLinks: DownloadLink[];
  eula?: boolean;
  highlight: {
    highlightItems: Array<{
      description: string;
    }>;
    links?: Link[] | null;
    title: string | null;
  } | null;
  links?: Link[] | null;
  sectionId?: string;
  showPreviousVersionsLink?: boolean;
  showSummary?: boolean;
  software?: { name: string; slug: string };
  source?: Link | null;
  summary?: string[] | null;
  title?: string;
  titleSize?: "xlarge" | "large";
}

const DownloadCard = forwardRef<HTMLElement, Props>(function DownloadCard(
  {
    className,
    downloadLinks,
    eula = false,
    highlight,
    links,
    sectionId,
    showPreviousVersionsLink = false,
    software = { name: "", slug: "" },
    showSummary = false,
    summary,
    source,
    title,
    titleSize = "xlarge",
  },
  ref,
) {
  const previousVersionsLink = {
    label: "Previous versions",
    url: `/support/software/${software.slug}/downloads/previous-versions`,
  };

  return (
    <section
      className={className}
      css={css`
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 1200px;

        @media (max-width: ${mediaTabletLandscape}) {
          flex-direction: column;
          width: 100%;
        }

        @media (max-width: ${mediaPhoneOnly}) {
          gap: 1.5rem;
        }
      `}
      id={sectionId}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 2rem;
        `}
      >
        {title ? (
          <Text as={"h3"} color={"base"} size={titleSize} weight={"semibold"}>
            {title}
          </Text>
        ) : null}

        {showSummary && summary && summary?.length ? (
          <Summary summary={summary} source={source} />
        ) : null}
      </div>

      <div
        css={css`
          display: grid;
          gap: 1.5rem;
        `}
      >
        <DownloadLinkList
          downloadLinks={downloadLinks}
          eula={eula}
          softwareSlug={software.slug}
        />

        {highlight && <DownloadHighlights {...highlight} />}
        {showPreviousVersionsLink ? (
          <NavLink link={previousVersionsLink} />
        ) : null}
        <div
          css={css`
            display: grid;
            gap: 0.5rem;
          `}
        >
          {links?.map((link, index) => (
            <NavLink key={index} link={link} />
          ))}
        </div>
      </div>
    </section>
  );
});

export default DownloadCard;
