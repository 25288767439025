import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import LocationCard from "../Cards/LocationCard";
import { REGION_LABELS } from "./constants";

interface Props {
  locations?: {
    __typename?: "Location";
    city: string | null;
    country: string | null;
    locality: string | null;
    name: string | null;
    phone: Array<string> | null;
    postalCode: string | null;
    region: string;
    state: string | null;
    formattedAddress: string | null;
  }[];
  regions?: {
    __typename?: "Region";
    label: string;
    slug: string;
    contactSales: {
      __typename?: "Region_ContactSales";
      label: string | null;
      url: string | null;
    } | null;
  }[];
}

const Locations: FunctionComponent<Props> = ({ locations, regions }) => {
  const sortedLocations = regions?.map((region) => {
    const offices = locations?.filter((location) => {
      return location.region === region.slug;
    });
    return {
      ...region,
      offices,
    };
  });

  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;
      `}
    >
      {sortedLocations?.length
        ? sortedLocations.map((region) => {
            const { contactSales, offices, slug } = region;
            const title = `${REGION_LABELS[slug]} Locations`;

            return (
              <LocationCard
                action={contactSales}
                key={title}
                locations={offices || []}
                title={title}
              />
            );
          })
        : null}
    </ul>
  );
};

export default Locations;
