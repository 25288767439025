import {
  Assays,
  CellMultiplexings,
  LibraryTypes,
  Pipelines,
} from "./constants";
import { CRPipelineSelectorData } from "./types";

// prettier-ignore
export const Documentations = {
  GEX_COUNT: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#count" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-gex-count#cr-count-gex" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-outputs-overview#count" },
    { label: "Tutorial", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-ct" },
  ],
  GEX_ANTIBODY_CRISPR_COUNT: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#count" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-gex-count#cr-count-gex-fb" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-outputs-overview#count" },
  ],
  ANTIBODY_COUNT: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#count" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-gex-count#cr-only-ab" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-outputs-overview#count" },
  ],
  CMO_GEX_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-3p-multi#cellranger-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-3p-outputs-cellplex" },
    { label: "Tutorial", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-cp" },
  ],
  CMO_GEX_ANTIBODY_CRISPR_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-3p-multi#cellranger-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-3p-outputs-cellplex" },
  ],
  OCM_GEX_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-3p-multi#cellranger-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-3p-outputs-cellplex" },
  ],
  HASHTAG_GEX_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-3p-multi#cellranger-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-3p-outputs-cellplex" },
  ],
  VDJ: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#vdj" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-vdj" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-vdj#overview" },
    { label: "Tutorial", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-vdj" },
  ],
  GEX_VDJ_COUNT: [
    { label: "Input (count)", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview" },
    { label: "Running the pipeline (count)", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-gex-count#cr-count-gex" },
    { label: "Output (count)", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-outputs-overview#count" },
    { label: "Tutorial (count)", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-ct" },
    { label: "Input (vdj)", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#vdj" },
    { label: "Running the pipeline (vdj)", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-vdj" },
    { label: "Output (vdj)", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-vdj#overview" },
    { label: "Tutorial (vdj)", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-vdj" },
  ],
  GEX_VDJ_ANTIBODY_COUNT: [
    { label: "Input (count)", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview" },
    { label: "Running the pipeline (count)", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-gex-count#cr-count-gex-fb" },
    { label: "Output (count)", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-outputs-overview#count" },
    { label: "Tutorial (count)", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-ct" },
    { label: "Input (vdj)", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#vdj" },
    { label: "Running the pipeline (vdj)", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-vdj" },
    { label: "Output (vdj)", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-vdj#overview" },
    { label: "Tutorial (vdj)", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-vdj" },
  ],
  GEX_VDJ_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-multi" },
    { label: "Tutorial", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-multi" },
  ],
  GEX_VDJ_ANTIBODY_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-multi" },
  ],
  GEX_VDJ_BEAM_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-multi" },
    { label: "Tutorial (BEAM-ab)", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-multi-beam-ab" },
    { label: "Tutorial (BEAM-t)", link: "/support/software/cell-ranger/latest/tutorials/cr-tutorial-multi-beam-t" },
  ],
  GEX_VDJ_ANTIBODY_BEAM_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-multi" },
  ],
  GEX_VDJ_ANTIBODY_CRISPR_MULTI: [
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-5p-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-5p-outputs-overview-multi" },
  ],
  FLEX_SINGLEPLEX_GEX_MULTI:[
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-flex-multi-frp#cellranger-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-flex-outputs-frp#sfrp" },
  ],
  FLEX_MULTIPLEX_GEX_MULTI:[
    { label: "Input", link: "/support/software/cell-ranger/latest/analysis/inputs/cr-inputs-overview#multi" },
    { label: "Running the pipeline", link: "/support/software/cell-ranger/latest/analysis/running-pipelines/cr-flex-multi-frp#cellranger-multi" },
    { label: "Output", link: "/support/software/cell-ranger/latest/analysis/outputs/cr-flex-outputs-frp#mfrp-multidir" },
  ]
};

export const crPipelineSelectorData: CRPipelineSelectorData = {
  [Assays.UNIVERSAL_3_GENE_EXPRESSION]: {
    [CellMultiplexings.SINGLE_PLEX]: {
      [LibraryTypes.GEX]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_COUNT,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_ANTIBODY_CRISPR_COUNT,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_ANTIBODY_CRISPR_COUNT,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_ANTIBODY_CRISPR_COUNT,
        },
      ],
      [LibraryTypes.ANTIBODY]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.ANTIBODY_COUNT,
        },
      ],
    },

    [CellMultiplexings.CELLPLEX]: {
      [LibraryTypes.GEX]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.CMO_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.CMO_GEX_ANTIBODY_CRISPR_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.CMO_GEX_ANTIBODY_CRISPR_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.CMO_GEX_ANTIBODY_CRISPR_MULTI,
        },
      ],
    },

    [CellMultiplexings.ON_CHIP_MULTIPLEXING]: {
      [LibraryTypes.GEX]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
    },

    [CellMultiplexings.HASHTAG_MULTIPLEXING]: {
      [LibraryTypes.GEX]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
    },
  },

  [Assays.UNIVERSAL_5_GENE_EXPRESSION]: {
    [CellMultiplexings.SINGLE_PLEX]: {
      [LibraryTypes.GEX]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_COUNT,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_ANTIBODY_CRISPR_COUNT,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_ANTIBODY_CRISPR_COUNT,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.GEX_ANTIBODY_CRISPR_COUNT,
        },
      ],
      [LibraryTypes.ANTIBODY]: [
        {
          pipeline: Pipelines.CELLRANGER_COUNT,
          documentations: Documentations.ANTIBODY_COUNT,
        },
      ],
      [LibraryTypes.VDJ]: [
        {
          pipeline: Pipelines.VDJ,
          documentations: Documentations.VDJ,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.GEX_VDJ_MULTI,
          recommended: true,
          considerations: [
            "Next generation command to analyze multiple library types together, enabling more consistent cell calling between the V(D)J and gene expression data",
          ],
        },
        {
          pipeline: Pipelines.CELLRANGER_COUNT_VDJ,
          documentations: Documentations.GEX_VDJ_COUNT,
          considerations: [
            "Legacy commands to independently generate Gene by Cell count matrix and clonotypes",
          ],
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.GEX_VDJ_ANTIBODY_MULTI,
          recommended: true,
          considerations: [
            "Next generation command to analyze multiple library types together, enabling more consistent cell calling between the V(D)J and gene expression data",
          ],
        },
        {
          pipeline: Pipelines.CELLRANGER_COUNT_VDJ,
          documentations: Documentations.GEX_VDJ_ANTIBODY_COUNT,
          considerations: [
            "Legacy commands to independently generate Gene by Cell count matrix and clonotypes",
          ],
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.BEAM}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.GEX_VDJ_BEAM_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}+${LibraryTypes.BEAM}`]:
        [
          {
            pipeline: Pipelines.CELLRANGER_MULTI,
            documentations: Documentations.GEX_VDJ_ANTIBODY_BEAM_MULTI,
          },
        ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]:
        [
          {
            pipeline: Pipelines.CELLRANGER_MULTI,
            documentations: Documentations.GEX_VDJ_ANTIBODY_CRISPR_MULTI,
            recommended: true,
            considerations: [
              "Next generation command to analyze multiple library types together, enabling more consistent cell calling between the V(D)J and gene expression data",
            ],
          },
          {
            pipeline: Pipelines.CELLRANGER_COUNT_VDJ,
            documentations: Documentations.GEX_VDJ_ANTIBODY_COUNT,
            considerations: [
              "Legacy commands to independently generate Gene by Cell count matrix and clonotypes",
            ],
          },
        ],
    },

    [CellMultiplexings.ON_CHIP_MULTIPLEXING]: {
      [LibraryTypes.GEX]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [LibraryTypes.ANTIBODY]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]:
        [
          {
            pipeline: Pipelines.CELLRANGER_MULTI,
            documentations: Documentations.OCM_GEX_MULTI,
          },
        ],
      [LibraryTypes.VDJ]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.OCM_GEX_MULTI,
        },
      ],
    },

    [CellMultiplexings.HASHTAG_MULTIPLEXING]: {
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [LibraryTypes.ANTIBODY]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.HASHTAG_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.VDJ}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]:
        [
          {
            pipeline: Pipelines.CELLRANGER_MULTI,
            documentations: Documentations.HASHTAG_GEX_MULTI,
          },
        ],
    },
  },

  [Assays.FLEX_GENE_EXPRESSION]: {
    [CellMultiplexings.SINGLE_PLEX]: {
      [`${LibraryTypes.GEX}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_SINGLEPLEX_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_SINGLEPLEX_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_SINGLEPLEX_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_SINGLEPLEX_GEX_MULTI,
        },
      ],
    },

    [CellMultiplexings.MULTI_PLEX]: {
      [`${LibraryTypes.GEX}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_MULTIPLEX_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_MULTIPLEX_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.ANTIBODY}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_MULTIPLEX_GEX_MULTI,
        },
      ],
      [`${LibraryTypes.GEX}+${LibraryTypes.CRISPR}`]: [
        {
          pipeline: Pipelines.CELLRANGER_MULTI,
          documentations: Documentations.FLEX_MULTIPLEX_GEX_MULTI,
        },
      ],
    },
  },
};
