import type { IconSource } from "@10xdev/types/models/IconSource";
import type { LinkItem } from "@10xdev/types/models/LinkItem";
import {
  borderStandard,
  colorBlueDark,
  layoutWidth1200,
  mediaDesktop,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { SPACINGS } from "../../../constants";
import Anchor from "../../Anchor";
import { ListCard } from "../../Cards";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Slash from "../../Slash";
import Text from "../../Text";
import ListItem from "../LinkGroup/ListItem";
import Callout from "./Callout";

interface Props {
  border?: boolean;
  bottomSpacing?: "none" | "small" | "medium" | "large";
  bulletList?: Array<string>;
  callout?: string;
  calloutIcon?: IconSource;
  calloutIconSize?: string;
  description?: Array<string>;
  descriptionParagraphs?: Array<string>;
  descriptionSize?: "large" | "medium";
  eyebrow?: string;
  heading: string;
  headingSize?: "xxxlarge" | "xxlarge";
  link?: LinkItem;
  linkList?: Array<LinkItem>;
  list?: Array<any>;
  narrow?: boolean;
  sectionInfoList?: Array<any>;
  slash: boolean;
}

const SectionInfo: FunctionComponent<Props> = (props) => {
  const {
    border = false,
    bottomSpacing = "medium",
    bulletList,
    callout,
    calloutIcon = "blink",
    calloutIconSize,
    eyebrow,
    heading,
    headingSize = "xxxlarge",
    description,
    descriptionParagraphs = description,
    descriptionSize = "medium",
    link,
    linkList,
    list,
    narrow = false,
    sectionInfoList = list,
    slash,
  } = props;

  const columnCss = css`
    :last-of-type {
      padding-top: 0;
    }

    @media (min-width: ${mediaTabletLandscape}) {
      box-sizing: border-box;

      :first-of-type {
        flex: ${narrow ? "3" : "4"};
        margin-right: 10%;
      }

      :last-of-type {
        flex: 5;
        margin-right: ${narrow ? "10%" : "0"};
        padding-top: ${eyebrow ? "3.5rem" : 0};
      }
    }
  `;

  return (
    <div
      className={"SectionInfo"}
      css={css`
        border-bottom: ${border ? borderStandard : "none"};
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem ${SPACINGS[bottomSpacing]};
        width: 100%;

        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    >
      <div
        css={css`
          @media (min-width: ${mediaTabletLandscape}) {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            width: 100%;
          }
        `}
      >
        <div css={columnCss}>
          <Slash slash={slash}>
            {eyebrow ? (
              <div
                css={css`
                  margin-bottom: 0.75rem;

                  @media (min-width: ${mediaTabletLandscape}) {
                    margin-bottom: 1.5rem;
                  }
                `}
              >
                <Text
                  as={"div"}
                  color={"blue"}
                  responsive={true}
                  size={"large"}
                  weight={"semibold"}
                >
                  {eyebrow}
                </Text>
              </div>
            ) : null}
            <div
              css={css`
                margin-bottom: 1.5rem;

                @media (min-width: ${mediaTabletLandscape}) {
                  margin-bottom: 0;
                }
              `}
            >
              <Heading
                as={"h3"}
                css={css`
                  margin-bottom: ${link ? "2rem" : 0};
                `}
                responsive={true}
                size={headingSize}
              >
                {heading}
              </Heading>
              {link ? (
                <Text
                  as={"div"}
                  color={"blue"}
                  css={css`
                    margin-bottom: 0.25rem;
                  `}
                  size={"small"}
                  weight={"semibold"}
                >
                  {link.url ? (
                    <Anchor href={link.url} scroll={false} target={"_blank"}>
                      {link.label}
                      {link.type ? (
                        <Icon
                          css={{ marginLeft: "1rem" }}
                          size={"14px"}
                          source={link.type}
                          yPos={"2px"}
                        />
                      ) : null}
                    </Anchor>
                  ) : null}
                </Text>
              ) : null}
            </div>
          </Slash>
        </div>

        {descriptionParagraphs || linkList ? (
          <div css={columnCss}>
            <div
              css={css`
                @media (min-width: ${mediaTabletLandscape}) {
                  padding-bottom: ${list ? ".5rem" : 0};
                }
              `}
            >
              {descriptionParagraphs?.length
                ? descriptionParagraphs.map((paragraph) => {
                    return (
                      <Text
                        as={"div"}
                        color={"midgray"}
                        css={css`
                          margin-bottom: 1.5rem;
                        `}
                        key={paragraph}
                        responsive={true}
                        size={descriptionSize}
                      >
                        {paragraph}
                      </Text>
                    );
                  })
                : null}

              {bulletList?.length ? (
                <ul
                  css={css`
                    padding-left: 1rem;
                    margin-bottom: 2.5rem;
                  `}
                >
                  {bulletList.map((listItem) => {
                    return (
                      <Text
                        as={"li"}
                        color={"midgray"}
                        css={css`
                          margin-bottom: 0.5rem;
                        `}
                        key={listItem}
                        size={"medium"}
                      >
                        {listItem}
                      </Text>
                    );
                  })}
                </ul>
              ) : null}

              {callout ? (
                <Callout icon={calloutIcon} iconSize={calloutIconSize}>
                  {callout}
                </Callout>
              ) : null}

              {linkList?.length ? (
                <ul
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                  `}
                >
                  {linkList.map((link) => {
                    const { label, type, url } = link;
                    return (
                      <li key={url}>
                        <Text
                          as={"span"}
                          color={"blue"}
                          size={"small"}
                          weight={"semibold"}
                        >
                          {url ? (
                            <Anchor
                              color={"inherit"}
                              hoverColor={colorBlueDark}
                              href={url}
                            >
                              {label}
                              {type ? (
                                <Icon
                                  size={"12px"}
                                  source={type}
                                  xPos={"16px"}
                                  yPos={"1px"}
                                />
                              ) : null}
                            </Anchor>
                          ) : null}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>

            {sectionInfoList?.length ? (
              <ListCard background={"gray"}>
                {sectionInfoList.map((item) => {
                  return <ListItem item={item} key={item.title} />;
                })}
              </ListCard>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SectionInfo;
