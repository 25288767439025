import Flex from "../../Flex";
import Text from "../../Text";
import Grid from "../../Grid";
import Icon from "../../Icon";
import TagLabel from "./TagLabel";
import { css } from "@emotion/react";
import {
  colorBlueDark,
  colorBlueMedium,
  colorWhite,
} from "@10xdev/design-tokens";
import { PipelineSelectorOutput } from "./types";

interface Props {
  pipelines: PipelineSelectorOutput;
}

const PipelineDocumentation = ({ pipelines }: Props) => {
  return (
    <Flex flexDirection="column" gap="1.5rem">
      <Flex alignItems="flex-start" flexDirection="column" gap="0.5rem">
        <Flex
          flexDirection={{ base: "column", tabletPortrait: "row" }}
          gap="0.5rem"
        >
          <Text as="p" color="steelDarkest" size="small" weight="semibold">
            Pipeline for your library type:
          </Text>
          {pipelines.map(({ pipeline }, index) => (
            <>
              {index > 0 ? (
                <Text
                  as="span"
                  color="steelDarkest"
                  size="small"
                  weight="medium"
                >
                  or
                </Text>
              ) : null}
              <TagLabel key={pipeline} text={pipeline} />
            </>
          ))}
        </Flex>

        {pipelines.length === 1 ? (
          <Text as="p" color="steelDarkest" size="small" weight="regular">
            Find specific analysis resources and documentation for your pipeline
            below.
          </Text>
        ) : null}

        {pipelines.length > 1 ? (
          <Text as="p" color="steelDarkest" size="small" weight="regular">
            You can use either pipeline to analyze data for your selected
            library type. Find specific analysis resources and documentation for
            your pipeline below.
          </Text>
        ) : null}
      </Flex>
      <Grid
        gridTemplateColumns={{ base: "1fr", tabletPortrait: "1fr 1fr" }}
        gap={{ base: "2rem", tabletPortrait: "3.5rem" }}
      >
        {pipelines.map(
          ({ pipeline, documentations, recommended, considerations }) => (
            <Flex flexDirection="column" gap="1rem" key={pipeline}>
              <Grid gridTemplateColumns="1fr" gap="0.5rem">
                <Text
                  as="p"
                  color="steelDarkest"
                  size="small"
                  weight="semibold"
                >
                  {`${pipeline}${recommended ? " (recommended)" : ""}`}
                </Text>
                {documentations.length > 0 &&
                considerations &&
                considerations.length > 0 ? (
                  <Grid gap="0.5rem">
                    {considerations.map((consideration) => (
                      <Text as="span" key={consideration} size="xsmall">
                        {consideration}
                      </Text>
                    ))}
                  </Grid>
                ) : null}
              </Grid>
              <Grid gridTemplateColumns="1fr" gap="1rem">
                {documentations.map((documentation) => (
                  // Unable to use Anchor component from @10xdev/ui
                  // because for some reason Next/Link does not respect
                  // the scroll-margin-top css property. it does not navigate
                  // to the correct section when href contains a hash
                  <a
                    css={css`
                      color: ${colorBlueMedium};
                      cursor: pointer;
                      text-decoration: none;
                      &:hover {
                        color: ${colorBlueDark};
                      }

                      &:focus,
                      &:focus-visible {
                        outline: 2px auto;
                      }

                      &:focus:not(:focus-visible) {
                        outline: none;
                      }
                    `}
                    href={documentation.link}
                    key={documentation.link}
                  >
                    <Flex
                      alignItems="center"
                      css={css`
                        background: ${colorWhite};
                        border-radius: 6px;
                        border: 1px solid rgba(68, 89, 121, 0.06);
                        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.06);
                      `}
                      justifyContent="space-between"
                      padding="0.75rem 1.5rem"
                    >
                      <Text
                        as="span"
                        color="inherit"
                        size="xsmall"
                        weight="semibold"
                      >
                        {documentation.label}
                      </Text>
                      <Icon source="nav-right" size="10px" />
                    </Flex>
                  </a>
                ))}
              </Grid>
            </Flex>
          ),
        )}
      </Grid>
    </Flex>
  );
};

export default PipelineDocumentation;
