import { css } from "@emotion/react";
import Flex from "../../Flex";
import Text from "../../Text";
import Button from "../../Button";
import { useForm } from "../../FormBuilder";

type Props = {
  onClearSelections?: () => void;
};

const Header = ({ onClearSelections }: Props) => {
  const form = useForm();
  return (
    <Flex
      flexDirection={{ base: "column", tabletPortrait: "row" }}
      alignItems={{ base: "flex-start", tabletPortrait: "center" }}
      gap={{ base: "0.5rem", tabletPortrait: "1.5rem" }}
      justifyContent="flex-start"
    >
      <Text as="h4" color="steelDarkest" size="medium" weight="semibold">
        Find your primary pipeline and learn how to use it
      </Text>
      {form.values && Object.keys(form.values).length > 0 && (
        <Button
          background="transparent"
          border={false}
          color="blue"
          css={css`
            height: auto;
            max-width: none;
            margin: 0;
            padding: 0;
            width: auto;
          `}
          onClick={() => {
            form.setValues({});
            onClearSelections?.();
          }}
        >
          <Text as="span" color="inherit" size="xsmall" weight="medium">
            Clear Selection
          </Text>
        </Button>
      )}
    </Flex>
  );
};

export default Header;
