import { EventFacets } from "@10xdev/types/models/Algolia/EventFacets";

export const EVENT_FACETS = [
  {
    attribute: EventFacets.categoryLabel,
    label: "Category",
  },
  {
    attribute: EventFacets.regionLabel,
    label: "Location",
  },
  {
    attribute: EventFacets.country,
    label: "Country / Region",
  },
  {
    attribute: EventFacets.researchAreas,
    label: "Research areas",
  },
  {
    attribute: EventFacets.productsName,
    label: "Products",
  },
];
