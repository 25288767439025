import { borderStandard, layoutWidth800 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { useEffect } from "react";
import type { HitsProvided } from "react-instantsearch-core";
import { connectHits } from "react-instantsearch-dom";

import Heading from "../../Heading";
import Text from "../../Text";
import Grid from "../../Grid";
import type { IndexWithHits } from "../constants";
import type { AlgoliaRecord } from "../types";
import Hits from "./Hits";
import Pagination from "./Pagination";

type Props = {
  children: ReactNode;
  component: IndexWithHits["hitComponent"];
  expanded: boolean;
  hitsPerPage: number;
  onHits: () => void;
  title: string;
} & HitsProvided<AlgoliaRecord>;

const HeaderCss = css`
  align-items: baseline;
  border-bottom: ${borderStandard};
  gap: 0.75rem;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 1.25rem;
`;

const ResultsCss = css`
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 120px;
    grid-row-gap: 2.5rem;
    list-style: none;
    margin: 0 0 3rem;
    padding: 0;
    @media (max-width: ${layoutWidth800}) {
      grid-template-columns: 1fr;
    }
  }
`;

function Results({
  hits,
  children,
  component,
  hitsPerPage,
  title,
  onHits,
}: Props) {
  const hasHits = hits.length;

  useEffect(() => {
    if (hasHits) {
      onHits();
    }
  }, [hasHits, onHits]);

  return (
    <div
      css={css`
        order: ${hasHits ? 1 : 2};
      `}
    >
      <Grid
        gridTemplateColumns={{ base: "1fr", tabletPortrait: "auto auto" }}
        css={HeaderCss}
      >
        <div
          css={css`
            align-items: baseline;
            display: flex;
            gap: 2rem;
          `}
        >
          <Heading
            as={"h2"}
            css={css`
              margin-bottom: 0;
            `}
            size={"xlarge"}
          >
            {title}
          </Heading>
        </div>

        <div
          css={css`
            align-items: center;
            display: flex;
          `}
        >
          <Pagination hitsPerPage={hitsPerPage} />
        </div>
      </Grid>

      {hasHits ? (
        <div css={ResultsCss}>
          <Hits hitComponent={component} hits={hits} />
        </div>
      ) : (
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"medium"}
        >
          {"There are no results for this query."}
        </Text>
      )}

      {children}
    </div>
  );
}

export default connectHits<Props, AlgoliaRecord>(Results);
