import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLightest,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import type { LinkItem } from "@10xdev/types/models/LinkItem";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Heading from "../Heading";
import Icon from "../Icon";
import Image from "../Image";
import Text from "../Text";

interface Props {
  border?: boolean;
  callout?: {
    link?: LinkItem;
    title?: string;
  };
  description?: string;
  image?: {
    alt?: string;
    url: string;
  };
  linkList?: Array<LinkItem>;
  padding?: string;
  title?: string;
}

const columnCss = css`
  box-sizing: border-box;
  height: 100%;

  @media (max-width: ${mediaTabletPortrait}) {
    margin-right: 0;
  }
`;

const TopicCard: FunctionComponent<Props> = ({
  border = true,
  callout,
  description,
  image,
  linkList,
  title,
  padding = "3rem 0",
}) => {
  return (
    <li
      css={css`
        border-bottom: ${border ? borderStandard : "none"};
        box-sizing: border-box;
        display: flex;
        flex-wrap: nowrap;
        height: 100%;
        margin: 0;
        padding: ${padding};
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          ${columnCss};

          flex: 3;
          margin-right: 10%;

          @media (max-width: ${mediaTabletLandscape}) {
            margin-bottom: 1.5rem;
          }
        `}
      >
        <Heading as={"h4"} size={"xlarge"}>
          {title}
        </Heading>
      </div>

      <div
        css={css`
          ${columnCss};

          display: flex;
          flex: 5;
          flex-direction: column;
          margin-right: 10%;
        `}
      >
        {description ? (
          <Text
            as={"p"}
            color={"midgray"}
            css={css`
              margin-bottom: ${image || callout || linkList ? "1.5rem" : "0px"};
            `}
            responsive={true}
            size={"medium"}
          >
            {description}
          </Text>
        ) : null}

        {image?.url ? (
          <div
            css={css`
              margin-bottom: 1.5rem;
              max-width: 60%;
            `}
          >
            <Image alt={image.alt} src={image.url} />
          </div>
        ) : null}

        {callout ? (
          <div
            css={css`
              background-color: ${colorBlueLightest};
              border-radius: ${borderRadiusMedium};
              padding: 1.5rem;
            `}
          >
            {callout.title ? (
              <Text
                as={"p"}
                color={"midgray"}
                css={css`
                  margin-bottom: 1rem;
                `}
                responsive={true}
                size={"small"}
                weight={"medium"}
              >
                {callout.title}
              </Text>
            ) : null}

            {callout?.link?.url ? (
              <Text
                as={"div"}
                color={"blue"}
                size={"small"}
                weight={"semibold"}
              >
                <Anchor
                  color={"inherit"}
                  hoverColor={colorBlueDark}
                  href={callout.link.url}
                >
                  {callout.link.label}
                  {callout.link.type ? (
                    <Icon
                      size={"12px"}
                      source={callout.link.type}
                      xPos={"16px"}
                      yPos={"1px"}
                    />
                  ) : null}
                </Anchor>
              </Text>
            ) : null}
          </div>
        ) : null}

        {linkList?.length ? (
          <ul
            css={css`
              display: flex;
              flex-direction: column;
              gap: 1.125rem;
              list-style: none;
              margin: 0;
              padding: 0;
            `}
          >
            {linkList.map((link) => {
              const { label, type, url } = link;
              return (
                <li key={url}>
                  <Text
                    as={"div"}
                    color={"blue"}
                    size={"small"}
                    weight={"semibold"}
                  >
                    {url ? (
                      <Anchor
                        aria-label={`${title}: ${label}`}
                        color={"inherit"}
                        hoverColor={colorBlueDark}
                        href={url}
                      >
                        {label}
                        {type ? (
                          <Icon
                            size={"12px"}
                            source={type}
                            xPos={"12px"}
                            yPos={"1.5px"}
                          />
                        ) : null}
                      </Anchor>
                    ) : null}
                  </Text>
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    </li>
  );
};

export default TopicCard;
