import type { MDXComponents as MDXComponentsType } from "mdx/types.js";
import { MDXBaseComponents } from "./MDXBaseComponents";
import MDXScope from "./MDXScope";

const MDXComponents = {
  ...MDXBaseComponents,
  ...MDXScope,
} as any as MDXComponentsType;

export default MDXComponents;
