import { css } from "@emotion/react";
import {
  Field,
  Label,
  RadioGroup as HeadlessUIRadioGroup,
} from "@headlessui/react";
import Radio from "./Radio";
import Flex from "../Flex";
import Text from "../Text";

interface RadioOption {
  label: string;
  value: string;
}

interface RadioGroupProps {
  disabled?: boolean;
  options: RadioOption[];
  selectedValue?: string;
  onChange: (value: string) => void;
  name: string;
}

const RadioGroup = ({
  disabled = false,
  options,
  selectedValue = "",
  onChange,
  name,
}: RadioGroupProps) => {
  return (
    <div>
      <HeadlessUIRadioGroup
        value={selectedValue}
        onChange={onChange}
        name={name}
      >
        <Flex flexWrap="wrap" gap="1rem">
          {options.map((option) => (
            <Field
              key={option.value}
              as="div"
              css={css`
                display: flex;
                flex: 0 0 auto;
                align-items: center;
              `}
            >
              <Radio value={option.value} disabled={disabled} />
              <Label
                css={css`
                  cursor: ${disabled ? "not-allowed" : "pointer"};
                  padding-left: 0.5rem;
                `}
              >
                <Text
                  as="span"
                  color={disabled ? "steelMedium" : "steelDarkest"}
                  size="xsmall"
                  weight="regular"
                >
                  {option.label}
                </Text>
              </Label>
            </Field>
          ))}
        </Flex>
      </HeadlessUIRadioGroup>
    </div>
  );
};

export default RadioGroup;
