export const Assays = {
  UNIVERSAL_3_GENE_EXPRESSION: "Universal 3’ Gene Expression",
  UNIVERSAL_5_GENE_EXPRESSION: "Universal 5’ Gene Expression",
  FLEX_GENE_EXPRESSION: "Flex Gene Expression",
} as const;

export const CellMultiplexings = {
  SINGLE_PLEX: "Singleplex",
  MULTI_PLEX: "Multiplex",
  CELLPLEX: "CellPlex Multiplexing",
  ON_CHIP_MULTIPLEXING: "On Chip Multiplexing (OCM)",
  HASHTAG_MULTIPLEXING: "Hashtag Multiplexing",
} as const;

export const LibraryTypes = {
  GEX: "GEX",
  ANTIBODY: "Antibody",
  VDJ: "VDJ",
  CRISPR: "CRISPR",
  BEAM: "BEAM",
} as const;

export const Pipelines = {
  CELLRANGER_COUNT: "cellranger count",
  CELLRANGER_MULTI: "cellranger multi",
  VDJ: "cellranger vdj",
  CELLRANGER_COUNT_VDJ: "cellranger count+vdj",
} as const;

export const CR_PIPELINE_SELECTOR_FORM_VALUES_STORAGE_KEY =
  "cellrangerPipelineSelectorFormValues";
