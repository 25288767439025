import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Icon from "../../Icon";
import type { IconSource } from "@10xdev/types/models/IconSource";
import Text from "../../Text";

interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: IconSource;
  onClick?: () => void;
  text?: string;
}

const ActionButton = ({
  children,
  className,
  disabled = false,
  icon,
  onClick,
  text,
  ...props
}: Props) => {
  return (
    <button
      className={className}
      css={css`
        background: transparent;
        border: 0;
        box-sizing: border-box;
        color: inherit;
        cursor: ${disabled ? "not-allowed" : "pointer"};
        font: inherit;
        padding: 0;
        margin: 0;
        outline: inherit;
      `}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {icon ? (
        <Icon
          css={css`
            color: inherit;
            &:hover {
              color: ${colorBlueDark};
            }
          `}
          size={"16px"}
          source={icon}
          yPos={"3px"}
        />
      ) : null}
      {text ? (
        <Text
          as={"span"}
          color={disabled ? "blueLight" : "blueMedium"}
          css={css`
            &:hover {
              color: ${colorBlueDark};
            }
          `}
          size={"xsmall"}
          weight={"medium"}
        >
          {text}
        </Text>
      ) : null}
      {children}
    </button>
  );
};

export default ActionButton;
