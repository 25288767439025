import type { AlgoliaUserGuide } from "@10xdev/types/models/Algolia/UserGuide";
import { colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { compact } from "lodash-es";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Mark from "../Connectors/Mark";
import Breadcrumb from "./Breadcrumb";

interface Props {
  hit: Hit<AlgoliaUserGuide>;
}

const UserGuide: FunctionComponent<Props> = ({ hit }) => {
  const userGuideBreadcrumb = compact(["support", "user-guides", hit.cgNumber]);
  return hit.documentDetailPermalink && hit.documentThumbnail ? (
    <Anchor
      css={css`
        display: flex;
      `}
      href={hit.documentDetailPermalink}
    >
      <img
        alt={`Cover for ${hit.fileName}`}
        css={css`
          align-self: flex-start;
          aspect-ratio: 8.5 / 11;
          border: solid 1px ${colorSteelLighter};
          margin-right: 2rem;
          object-fit: cover;
          object-position: left top;
          width: 72px;
        `}
        src={hit.documentThumbnail}
      />
      <div>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"fileName"} hit={hit} />
        </Heading>
        <Breadcrumb path={userGuideBreadcrumb} />
      </div>
    </Anchor>
  ) : null;
};

export default UserGuide;
