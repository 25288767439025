import { CR_PIPELINE_SELECTOR_FORM_VALUES_STORAGE_KEY } from "./constants";
import { crPipelineSelectorData } from "./data";
import {
  PipelineSelectorInputs,
  PipelineSelectorOutput,
  CRPipelineSelectorData,
  AssayType,
  CellMultiplexingType,
  PipelineSelectorFormValues,
} from "./types";

// Helper function to sort a key string (e.g., "GEX+ANTIBODY" to "ANTIBODY+GEX")
export const sortKey = (key: string) => key.split("+").sort().join("+");

// Function to get sorted library combinations
export function getSortedLibraryCombinations(
  output: CRPipelineSelectorData,
  assayKey: AssayType,
  multiplexingKey: CellMultiplexingType,
): Record<string, PipelineSelectorOutput> | undefined {
  const libraryCombinations = output[assayKey]?.[multiplexingKey];
  if (!libraryCombinations) {
    return undefined;
  }

  const sortedLibraryCombinations: Record<string, PipelineSelectorOutput> = {};

  for (const combination in libraryCombinations) {
    const sortedCombination = sortKey(combination);
    sortedLibraryCombinations[sortedCombination] =
      libraryCombinations[combination]!;
  }

  return sortedLibraryCombinations;
}

export function getPipelines({
  assay,
  cellMultiplexing,
  libraryTypes,
}: PipelineSelectorInputs): PipelineSelectorOutput | null | undefined {
  if (
    !assay ||
    !cellMultiplexing ||
    !libraryTypes ||
    libraryTypes?.length === 0
  ) {
    return undefined;
  }

  // Sort libraryTypes to form a consistent combination key for lookup
  const combinationKey = [...libraryTypes].sort().join("+");

  // Get a sorted version of the relevant part of crPipelineSelectorOutput
  const sortedLibraryCombinations = getSortedLibraryCombinations(
    crPipelineSelectorData,
    assay,
    cellMultiplexing,
  );

  // Access the sorted output using the combinationKey
  const pipelines = sortedLibraryCombinations?.[combinationKey];

  return pipelines || null;
}

export const getPersistedFormValues = () => {
  if (typeof window !== "undefined") {
    const savedValues = sessionStorage.getItem(
      CR_PIPELINE_SELECTOR_FORM_VALUES_STORAGE_KEY,
    );
    return savedValues
      ? (JSON.parse(savedValues) as PipelineSelectorFormValues)
      : {};
  }

  return {};
};

export const persistFormValues = (values: PipelineSelectorFormValues) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem(
      CR_PIPELINE_SELECTOR_FORM_VALUES_STORAGE_KEY,
      JSON.stringify(values),
    );
  }
};

export const clearPersistedFormValues = () => {
  if (typeof window !== "undefined") {
    sessionStorage.removeItem(CR_PIPELINE_SELECTOR_FORM_VALUES_STORAGE_KEY);
  }
};
