import { borderStandard, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import { getBorderRadius, getElevation } from "../utils";
import { generateResponsiveCSS, WithResponsive } from "@10xdev/utils";

export interface Props {
  align?: "start" | "center" | "end";
  as?: keyof JSX.IntrinsicElements;
  backgroundColor?: string;
  backgroundImage?: string;
  border?: {
    bottom: boolean;
    left: boolean;
    right: boolean;
    top: boolean;
  };
  borderRadius?: "small" | "medium" | "large";
  boxSizing?: "border-box" | "content-box";
  card?: {
    elevation?: "level1" | "level2";
  };
  center?: { element?: boolean; text?: boolean };
  children?: ReactNode;
  className?: string;
  display?: Property.Display;
  gap?: WithResponsive<Property.Gap>;
  id?: string;
  margin?: WithResponsive<Property.Margin>;
  maxWidth?: WithResponsive<Property.MaxWidth>;
  minWidth?: Property.MinWidth;
  outline?: boolean;
  padding?: WithResponsive<Property.Padding>;
  width?: string;
}

const Container = (props: Props) => {
  const {
    align = "start",
    as = "div",
    backgroundColor,
    backgroundImage,
    border,
    borderRadius,
    boxSizing = "content-box",
    card,
    center = { element: false, text: false },
    children,
    className,
    display = "grid",
    gap = 0,
    id,
    margin = "0 auto",
    maxWidth,
    outline = false,
    padding = 0,
    minWidth = 0,
    width,
  } = props;

  const Element = as;

  const cssString = generateResponsiveCSS({
    gap,
    margin,
    maxWidth,
    padding,
    width,
  });

  const propKeys = Object.keys(props);
  const wrap = propKeys.length > 1;

  if (wrap) {
    return (
      <Element
        className={className}
        css={css`
          align-content: ${align};
          ${backgroundColor ? `background: ${backgroundColor}` : null};
          ${backgroundImage
            ? `background-image: url('${backgroundImage}')`
            : null};
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top center;

          ${border?.bottom ? `border-bottom: ${borderStandard}` : null};
          ${border?.top ? `border-top: ${borderStandard}` : null};
          ${border?.left ? `border-left: ${borderStandard}` : null};
          ${border?.right ? `border-right: ${borderStandard}` : null};
          ${borderRadius
            ? `border-radius: ${getBorderRadius(borderRadius)}`
            : null};
          ${card?.elevation ? getElevation(card.elevation) : null};
          box-sizing: ${boxSizing};
          display: ${display};
          gap: ${gap};
          ${center.element ? "justify-self: center" : null};
          ${outline ? `outline: ${borderStandard}` : null};
          overflow: hidden;
          ${center.text ? "text-align: center" : null};
          @media (min-width: ${mediaTabletLandscape}) {
            min-width: ${minWidth};
          }
          ${cssString}
        `}
        id={id}
      >
        {children}
      </Element>
    );
  }

  return <>{props.children}</>;
};

export default Container;
