import { css } from "@emotion/react";
import { Field, Label } from "@headlessui/react";
import Checkbox from "./Checkbox";
import Flex from "../Flex";
import Text from "../Text";

interface CheckboxOption {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  disabled?: boolean;
  options: CheckboxOption[];
  values?: string[];
  onChange: (values: string[]) => void;
  name: string;
}

const CheckboxGroup = ({
  disabled = false,
  options,
  values = [],
  onChange,
  name,
}: CheckboxGroupProps) => {
  const handleCheckboxChange = (value: string) => {
    if (values.includes(value)) {
      onChange(values.filter((v) => v !== value));
    } else {
      onChange([...values, value]);
    }
  };

  return (
    <div>
      <Flex flexWrap="wrap" gap="1rem">
        {options.map((option) => (
          <Field
            disabled={disabled}
            key={option.value}
            as="div"
            css={css`
              display: flex;
              flex: 0 0 auto;
              align-items: center;
            `}
          >
            <Checkbox
              checked={values.includes(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
              name={name}
              value={option.value}
            />
            <Label
              css={css`
                cursor: ${disabled ? "not-allowed" : "pointer"};
                padding-left: 0.5rem;
              `}
            >
              <Text
                as="span"
                color={disabled ? "steelMedium" : "steelDarkest"}
                size="xsmall"
                weight="regular"
              >
                {option.label}
              </Text>
            </Label>
          </Field>
        ))}
      </Flex>
    </div>
  );
};

export default CheckboxGroup;
