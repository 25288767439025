import { css } from "@emotion/react";
import Flex from "../../Flex";
import Text from "../../Text";
import { borderRadiusMedium, colorGrayLightest } from "@10xdev/design-tokens";
import ThematicBreak from "../../ThematicBreak";
import PipelineDocumentation from "./PipelineDocumentation";
import { config } from "./formConfig";
import * as Yup from "yup";
import { PipelineSelectorFormValues, PipelineSelectorOutput } from "./types";
import FormBuilder, { FormikFormProvider } from "../../FormBuilder";
import { useState } from "react";
import Header from "./Header";
import {
  clearPersistedFormValues,
  getPersistedFormValues,
  getPipelines,
  persistFormValues,
} from "./utils";
import Grid from "../../Grid";
import Icon from "../../Icon";
import Anchor from "../../Anchor";

const CellRangerPipelineSelector = () => {
  const [output, setOutput] = useState<
    PipelineSelectorOutput | null | undefined
  >(undefined);

  const validationSchemaFields = config.fields.reduce((schema: any, field) => {
    if (field.validation) {
      schema[field.name as string] = field.validation;
    }
    return schema;
  }, {});

  const validationSchema = Yup.object().shape(validationSchemaFields);

  const handleChange = (values: PipelineSelectorFormValues) => {
    const output = getPipelines(values);
    setOutput(output);
    persistFormValues(values);
  };

  const handleClearSelections = () => {
    setOutput(undefined);
    clearPersistedFormValues();
  };

  const persistedValues = getPersistedFormValues();

  return (
    <FormikFormProvider<PipelineSelectorFormValues>
      initialValues={persistedValues}
      validationSchema={validationSchema}
      onChange={handleChange}
      onSubmit={() => {}}
    >
      <Flex
        css={css`
          background: ${colorGrayLightest};
          border-radius: ${borderRadiusMedium};
        `}
        flexDirection="column"
        gap={{ base: "1rem", tabletPortrait: "1.5rem" }}
        padding={{ base: "1rem", tabletPortrait: "1.5rem 2rem" }}
      >
        <Header onClearSelections={handleClearSelections} />
        <FormBuilder config={config} />
        <ThematicBreak
          css={css`
            margin: 0 auto;
          `}
        />
        {output && output.length >= 0 ? (
          <PipelineDocumentation pipelines={output} />
        ) : output === null ? (
          <Flex alignItems="center" gap="1rem">
            <Icon
              css={css`
                flex: 0 0 auto;
              `}
              source="unhappy"
              size="24px"
            />
            <Grid gap="0.5rem">
              <Text as="p" color="steelDarkest" size="small" weight="semibold">
                The currently selected library type is invalid
              </Text>
              <Text as="p" color="steelDarkest" size="small" weight="regular">
                Please refine your selection to a valid combination. For details
                on valid library combinations, please see{" "}
                <Anchor
                  href="/support/software/cell-ranger/latest/resources/supported-libraries"
                  target="_blank"
                >
                  Supported Libraries
                </Anchor>
                .
              </Text>
            </Grid>
          </Flex>
        ) : (
          <Text as="p" color="steelDarkest" size="small" weight="regular">
            Select your library type(s) above, and we will guide you to the
            appropriate pipeline and corresponding documentation.
          </Text>
        )}
      </Flex>
    </FormikFormProvider>
  );
};

export default CellRangerPipelineSelector;
