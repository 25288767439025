import type { HeadingElement, TextSize } from "@10xdev/types";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";
import { useContext } from "react";

import LinkableTitle from "../LinkableTitle";
import { RefContext } from "./RefContext";
import { getSectionIdFromTitle } from "./utils";

interface Props {
  children?: ReactNode;
  className?: string;
  headingAs?: HeadingElement;
  headingSize?: TextSize;
  id?: string;
  title: string;
}

const LinkableSection: FunctionComponent<Props> = ({
  children,
  className,
  headingAs,
  headingSize,
  id,
  title,
}) => {
  const { handleRef, scrollMarginTop } = useContext(RefContext);

  // If no ID is provided, generate one from the title
  const titleId = getSectionIdFromTitle(id, title);

  return (
    <section
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        scroll-margin-top: ${scrollMarginTop}px;
      `}
      id={titleId}
      key={titleId}
    >
      <LinkableTitle
        as={headingAs}
        id={titleId}
        ref={(element) => {
          handleRef(titleId, element);
        }}
        size={headingSize}
        title={title}
      />
      {children}
    </section>
  );
};

export default LinkableSection;
