import { colorBlueLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Icon from "../../Icon";
import type { IconSource } from "@10xdev/types/models/IconSource";
import Text from "../../Text";

interface Props {
  children: string;
  icon?: IconSource;
  iconSize?: string;
  size?: "large" | "medium";
}

/**
 * A callout containing an icon and text.
 */
const Callout: FunctionComponent<Props> = ({
  children,
  icon,
  iconSize = "20px",
  size = "medium",
}) => {
  return (
    <div
      css={css`
        background: ${colorBlueLighter};
        border-radius: 6px;
        display: flex;
        padding: 1rem 1.25rem;
      `}
    >
      {icon ? (
        <Icon
          color={"midgray"}
          css={{ marginRight: ".75rem" }}
          size={iconSize}
          source={icon}
          yPos={"-1px"}
        />
      ) : null}
      <Text as={"div"} responsive={true} size={size} weight={"medium"}>
        {children}
      </Text>
    </div>
  );
};

export default Callout;
