import * as Yup from "yup";

import { PipelineSelectorFormValues } from "./types";
import { Assays, CellMultiplexings, LibraryTypes } from "./constants";
import Text from "../../Text";
import Grid from "../../Grid";
import { RequiredAsterisk, FormConfig } from "../../FormBuilder";

export const config: FormConfig<PipelineSelectorFormValues> = {
  id: "cellRangerPipelineSelector",
  fields: [
    {
      name: "assay",
      label: "Assay",
      type: "radio",
      options: [
        {
          label: Assays.UNIVERSAL_3_GENE_EXPRESSION,
          value: Assays.UNIVERSAL_3_GENE_EXPRESSION,
        },
        {
          label: Assays.UNIVERSAL_5_GENE_EXPRESSION,
          value: Assays.UNIVERSAL_5_GENE_EXPRESSION,
        },
        {
          label: Assays.FLEX_GENE_EXPRESSION,
          value: Assays.FLEX_GENE_EXPRESSION,
        },
      ],
      validation: Yup.string().required("Assay is required"),
    },
    {
      name: "cellMultiplexing",
      label: "Cell Multiplexing",
      type: "radio",
      options: (values) => {
        if (values.assay === Assays.UNIVERSAL_3_GENE_EXPRESSION) {
          return [
            {
              label: CellMultiplexings.SINGLE_PLEX,
              value: CellMultiplexings.SINGLE_PLEX,
            },
            {
              label: CellMultiplexings.CELLPLEX,
              value: CellMultiplexings.CELLPLEX,
            },
            {
              label: CellMultiplexings.ON_CHIP_MULTIPLEXING,
              value: CellMultiplexings.ON_CHIP_MULTIPLEXING,
            },
            {
              label: CellMultiplexings.HASHTAG_MULTIPLEXING,
              value: CellMultiplexings.HASHTAG_MULTIPLEXING,
            },
          ];
        } else if (values.assay === Assays.UNIVERSAL_5_GENE_EXPRESSION) {
          return [
            {
              label: CellMultiplexings.SINGLE_PLEX,
              value: CellMultiplexings.SINGLE_PLEX,
            },
            {
              label: CellMultiplexings.ON_CHIP_MULTIPLEXING,
              value: CellMultiplexings.ON_CHIP_MULTIPLEXING,
            },
            {
              label: CellMultiplexings.HASHTAG_MULTIPLEXING,
              value: CellMultiplexings.HASHTAG_MULTIPLEXING,
            },
          ];
        } else if (values.assay === Assays.FLEX_GENE_EXPRESSION) {
          return [
            {
              label: CellMultiplexings.SINGLE_PLEX,
              value: CellMultiplexings.SINGLE_PLEX,
            },
            {
              label: CellMultiplexings.MULTI_PLEX,
              value: CellMultiplexings.MULTI_PLEX,
            },
          ];
        } else {
          return [
            {
              label: CellMultiplexings.SINGLE_PLEX,
              value: CellMultiplexings.SINGLE_PLEX,
            },
            {
              label: CellMultiplexings.MULTI_PLEX,
              value: CellMultiplexings.MULTI_PLEX,
            },
            {
              label: CellMultiplexings.CELLPLEX,
              value: CellMultiplexings.CELLPLEX,
            },
            {
              label: CellMultiplexings.ON_CHIP_MULTIPLEXING,
              value: CellMultiplexings.ON_CHIP_MULTIPLEXING,
            },
            {
              label: CellMultiplexings.HASHTAG_MULTIPLEXING,
              value: CellMultiplexings.HASHTAG_MULTIPLEXING,
            },
          ];
        }
      },
      validation: Yup.string().required("Cell Multiplexing is required"),
      shouldEnable: (values) => !!values.assay,
      transformValue: (values, prevValues) => {
        if (prevValues && values.assay !== prevValues.assay) {
          return undefined;
        }
        return values.cellMultiplexing;
      },
    },
    {
      name: "libraryTypes",
      label: (values, { required, disabled }) => {
        return (
          <Grid>
            <Text
              as="div"
              color={disabled ? "steelMedium" : "steelDarkest"}
              size="xsmall"
              weight="medium"
            >
              <span>Library Types</span>
              {required ? <RequiredAsterisk disabled={disabled} /> : null}
            </Text>
            <Text
              as="div"
              color={disabled ? "steelMedium" : "steelDarkest"}
              size="xsmall"
            >
              <span>(Select all that apply)</span>
            </Text>
          </Grid>
        );
      },
      type: "checkbox",
      options: (values) => {
        if (
          values.assay === Assays.UNIVERSAL_3_GENE_EXPRESSION ||
          values.assay === Assays.FLEX_GENE_EXPRESSION
        ) {
          return [
            { label: LibraryTypes.GEX, value: LibraryTypes.GEX },
            { label: LibraryTypes.ANTIBODY, value: LibraryTypes.ANTIBODY },
            { label: LibraryTypes.CRISPR, value: LibraryTypes.CRISPR },
          ];
        } else {
          return [
            { label: LibraryTypes.GEX, value: LibraryTypes.GEX },
            { label: LibraryTypes.VDJ, value: LibraryTypes.VDJ },
            { label: LibraryTypes.ANTIBODY, value: LibraryTypes.ANTIBODY },
            { label: LibraryTypes.CRISPR, value: LibraryTypes.CRISPR },
            { label: LibraryTypes.BEAM, value: LibraryTypes.BEAM },
          ];
        }
      },
      validation: Yup.array()
        .of(Yup.string())
        .min(1, "At least one library type is required")
        .required(),
      shouldEnable: (values) => !!values.cellMultiplexing,
      transformValue: (values, prevValues) => {
        if (
          prevValues &&
          (values.assay !== prevValues.assay ||
            values.cellMultiplexing !== prevValues.cellMultiplexing)
        ) {
          return undefined;
        }

        return values.libraryTypes;
      },
    },
  ],
};
