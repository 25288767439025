import { mediaDesktop, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { Children, useRef } from "react";

import Button from "../Button";
import Icon from "../Icon";
import useCarousel from "./useCarousel";

interface Props {
  aspectRatio: number;
  children: ReactNode;
  gap?: number;
  itemHeight: number;
  itemsPerScroll?: number;
}

const Carousel = ({
  aspectRatio = 1.1,
  children,
  gap = 16,
  itemsPerScroll = 3,
  itemHeight = 312,
}: Props) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const {
    carouselCss,
    offSet,
    handlePreviousClick,
    handleNextClick,
    handleScrollDebounced,
  } = useCarousel({
    aspectRatio,
    children,
    gap,
    itemHeight,
    itemsPerScroll,
    carouselRef,
  });
  const showButtons = Children.toArray(children).length > 3;
  return (
    <div
      className={"Carousel"}
      css={css`
        overflow: hidden;
        position: relative;

        @media (max-width: ${mediaDesktop}) {
          overflow-x: scroll;
        }
        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    >
      <div css={carouselCss} onScroll={handleScrollDebounced} ref={carouselRef}>
        {children}
      </div>

      {showButtons ? (
        <>
          <div
            css={css`
              align-items: center;
              background: linear-gradient(
                270deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 86.24%
              );
              display: none;
              height: 100%;
              justify-content: start;
              position: absolute;
              top: 0;
              width: 120px;

              @media (min-width: ${mediaTabletLandscape}) {
                display: flex;
                opacity: ${offSet > 0 ? "1" : "0"};
                transition: opacity 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
              }
            `}
          >
            <Button
              background={"gray"}
              color={"white"}
              css={css`
                border-radius: 50%;
                height: 4rem;
                margin-left: 1rem;
                width: 4rem;
              `}
              onClick={handlePreviousClick}
            >
              <Icon size={"22px"} source={"nav-left"} xPos={"-1px"} />
            </Button>
          </div>
          <div
            css={css`
              align-items: center;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 86.24%
              );
              display: none;
              height: 100%;
              justify-content: end;
              left: 100%;
              position: absolute;
              top: 0;
              transform: translateX(-100%);
              width: 120px;

              @media (min-width: ${mediaTabletLandscape}) {
                display: flex;
              }
            `}
          >
            <Button
              background={"gray"}
              color={"white"}
              css={css`
                border-radius: 50%;
                display: none;
                height: 4rem;
                margin-right: 1rem;
                width: 4rem;

                @media (min-width: ${mediaTabletLandscape}) {
                  display: flex;
                }

                &:disabled > svg {
                  color: white;
                }
              `}
              onClick={handleNextClick}
              disabled={
                offSet + (carouselRef.current?.clientWidth || 0) >=
                (carouselRef.current?.scrollWidth || 0)
              }
            >
              <Icon size={"22px"} source={"nav-right"} xPos={"1px"} />
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Carousel;
