import type { AlgoliaSearchDocument } from "@10xdev/types/models/Algolia/SearchDocument";
import { colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Text from "../../Text";
import Mark from "../Connectors/Mark";
import Snippet from "../Connectors/Snippet";
import Breadcrumb from "./Breadcrumb";
import { getBreadcrumb, getHref, getThumbnail } from "./utils";

interface Props {
  hit: Hit<AlgoliaSearchDocument>;
}

const Document: FunctionComponent<Props> = ({ hit }) => {
  return (
    <Anchor
      css={css`
        display: flex;
      `}
      href={getHref(hit)}
    >
      <img
        alt={getThumbnail(hit).alt}
        css={css`
          align-self: flex-start;
          aspect-ratio: 8.5 / 11;
          border: solid 1px ${colorSteelLighter};
          margin-right: 2rem;
          object-fit: cover;
          width: 72px;
        `}
        src={getThumbnail(hit).src}
      />

      <div>
        <Heading
          as={"h3"}
          color={"inherit"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          <Mark attribute={"title"} hit={hit} />
        </Heading>
        <Text
          as={"p"}
          color={"midgray"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          size={"medium"}
        >
          <Snippet attribute={"description"} hit={hit} />
        </Text>
        <Breadcrumb path={getBreadcrumb(hit)} />
      </div>
    </Anchor>
  );
};

export default Document;
