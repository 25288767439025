import type { AlgoliaDataset } from "@10xdev/types/models/Algolia/Dataset";
import { css } from "@emotion/react";
import { compact } from "lodash-es";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Mark from "../Connectors/Mark";
import Breadcrumb from "./Breadcrumb";

interface Props {
  hit: Hit<AlgoliaDataset>;
}

const Dataset: FunctionComponent<Props> = ({ hit }) => {
  const product = hit.product;

  const datasetBreadcrumb = compact([
    "Datasets",
    product?.name,
    hit?.software?.name,
    hit?.pipeline?.version,
  ]);

  return (
    <Anchor href={`/datasets/${hit.slug}`}>
      <Heading
        as={"h3"}
        color={"inherit"}
        css={css`
          margin-bottom: 0.5rem;
        `}
        responsive={true}
        size={"medium"}
        weight={"regular"}
      >
        <Mark attribute={"title"} hit={hit} />
      </Heading>
      <Breadcrumb path={datasetBreadcrumb} />
    </Anchor>
  );
};

export default Dataset;
