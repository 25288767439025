import { FormFieldConfig } from "./types";

export const isFieldRequired = <TValues>(
  field: FormFieldConfig<TValues>,
  values: TValues,
): boolean => {
  if (!field.validation) {
    return false;
  }

  const isValid = field.validation.isValidSync(undefined, {
    context: values,
    parent: values,
  });

  return !isValid;
};
