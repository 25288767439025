import type { MDXComponents as MDXComponentsType } from "mdx/types";
import type { WithCompiledMdx } from "@10xdev/types/models/MDX";
import type { Person } from "@10xdev/types/models/Person";
import type { ResolvedCMSEntry } from "@10xdev/types/cms";
import { css } from "@emotion/react";
import { MDXRemote } from "next-mdx-remote";
import type { FunctionComponent } from "react";

import { MDXBaseComponents } from "../Blocks/MDX/MDXBaseComponents";
import Heading from "../Heading";
import Text from "../Text";
import NavList from "./NavList";
import NavTabs from "./NavTabs";
import Grid from "../Grid";
import Image from "../Image";

interface Props {
  person: Person & WithCompiledMdx & ResolvedCMSEntry;
  sidebar: any;
}

const Profile: FunctionComponent<Props> = ({ person, sidebar }) => {
  const fullName = `${person?.foreName} ${person?.lastName}`;

  return (
    <>
      {sidebar?.sections.length ? (
        <NavTabs active={sidebar?.type} sections={sidebar?.sections} />
      ) : null}

      <Grid
        css={css`
          margin: 0 auto;
          max-width: 1200px;
          padding-top: 3.5rem;
        `}
        gridTemplateColumns={{ base: "1fr", tabletLandscape: "20% 1fr" }}
        gap={"1rem 10%"}
      >
        <div>
          {sidebar?.list.length ? (
            <NavList
              currentSlug={person.slug}
              list={sidebar?.list}
              type={sidebar?.type}
            />
          ) : null}
        </div>

        {person ? (
          <Grid
            gridTemplateColumns={{ base: "1fr", tabletLandscape: "300px 1fr" }}
            gap="1rem 4rem"
          >
            {person?.avatar ? (
              <Image
                alt={fullName}
                css={css`
                  border-radius: 6px;
                `}
                src={person.avatar}
              />
            ) : null}

            <div>
              {person?.lastName ? (
                <>
                  <Heading
                    as={"h3"}
                    css={css`
                      margin-bottom: 0.25rem;
                    `}
                    size={"xxlarge"}
                  >
                    {fullName}
                  </Heading>

                  <Text
                    as={"div"}
                    css={css`
                      margin-bottom: 2.5rem;
                    `}
                    size={"medium"}
                    weight={"medium"}
                  >
                    {sidebar.type === "team" ? person?.position : null}
                  </Text>

                  {person?.mdx ? (
                    <MDXRemote
                      {...person?.mdx}
                      components={
                        MDXBaseComponents as unknown as MDXComponentsType
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default Profile;
