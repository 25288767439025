import { css } from "@emotion/react";

import Text from "../../Text";
import Anchor from "../../Anchor";
import type { Link } from "../../SoftwareDownload/DownloadCard/types";

interface Props {
  summary: string[];
  source?: Link | null;
}

const Summary = ({ summary, source }: Props) => {
  return (
    <ul
      css={css`
        list-style-type: disc;
        margin: 0;
        padding: 0 0 0 2rem;
      `}
    >
      {summary.map((item) => {
        return (
          <Text as={"li"} key={item} size={"small"}>
            {item}
          </Text>
        );
      })}

      {source?.url ? (
        <Text as={"li"} size={"small"} color={"base"}>
          <Anchor href={source.url}>{source.label}</Anchor>
        </Text>
      ) : null}
    </ul>
  );
};

export default Summary;
