import type { CompatibleProduct } from "@10xdev/types/models/CompatibleProduct";
import {
  colorBlueDark,
  mediaPhoneOnly,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";
import LinkList from "./LinkList";

interface Props {
  product: CompatibleProduct;
}

const ProductInfo: FunctionComponent<Props> = ({ product }: Props) => {
  return (
    <div
      css={css`
        box-sizing: border-box;
        padding: 2.25rem 3rem 1rem 0;
        width: 100%;
        display: flex;

        @media (max-width: ${mediaTabletLandscape}) {
          padding-right: 2rem;
        }

        @media (max-width: ${mediaPhoneOnly}) {
          flex-direction: column;
        }
      `}
    >
      <div
        css={css`
          flex-shrink: 0;
          margin-right: 19%;

          @media (max-width: ${mediaTabletLandscape}) {
            margin-right: 10%;
          }

          @media (max-width: ${mediaPhoneOnly}) {
            margin-bottom: 2rem;
          }
        `}
      >
        <Text
          as={"h4"}
          color={"steelDarker"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          size={"small"}
          weight={"medium"}
        >
          {product.companyName}
        </Text>

        <Anchor
          aria-label={`View ${product.name}`}
          color={"blue"}
          css={{ alignItems: "center", display: "flex" }}
          hoverColor={colorBlueDark}
          href={product.url}
          target={"_blank"}
        >
          <Text
            as={"span"}
            color={"inherit"}
            css={css`
              display: block;
              margin-right: 1rem;
            `}
            size={"small"}
            weight={"semibold"}
          >
            {"View Product"}
          </Text>
          <Icon size={"14px"} source={"external-link"} />
        </Anchor>
      </div>
      <div>
        <Text
          as={"p"}
          color={"steelDarker"}
          css={css`
            margin-bottom: 1.5rem;
          `}
          size={"medium"}
          weight={"regular"}
        >
          {product.description}
        </Text>
        <Text
          as={"div"}
          color={"steelDarker"}
          css={css`
            margin-bottom: 0.5rem;
          `}
          size={"small"}
          weight={"medium"}
        >
          {"Compatible with"}
        </Text>
        <LinkList list={product.compatibleProducts} />
      </div>
    </div>
  );
};

export default ProductInfo;
