import {
  colorWhite,
  layoutWidth1200,
  mediaPhoneOnly,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";

import Anchor from "../Anchor";
import Icon from "../Icon";
import Text from "../Text";
import ThematicBreak from "../ThematicBreak";
import CountrySelect from "./CountrySelect";
import { countryToLanguageMap, type Locale } from "@10xdev/language-utils";

// 200 limit is arbitrary, just to override the default limit of 10

const Header = () => {
  const { locale } = useRouter() || {};

  return (
    <div
      css={css`
        background: ${colorWhite};
        box-sizing: border-box;
        padding: 3.5rem 1rem;
        width: 100%;
      `}
    >
      <header
        css={css`
          background: ${colorWhite};
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          max-width: ${layoutWidth1200};

          @media (max-width: ${mediaPhoneOnly}) {
            flex-direction: column;
            flex-flow: column-reverse;
          }
        `}
      >
        {locale && (
          <CountrySelect
            attribute={`${countryToLanguageMap[locale as Locale]}.country`}
            limit={200}
          />
        )}
        <div
          css={css`
            display: none;

            @media (max-width: ${mediaPhoneOnly}) {
              display: flex;
            }
          `}
        >
          <ThematicBreak />
        </div>
        <div
          css={css`
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 400px;

            @media (max-width: ${mediaPhoneOnly}) {
              width: 100%;
            }
          `}
        >
          <Text as={"h6"} size={"medium"} weight={"semibold"}>
            {"Do you want to become one of our global distributors?"}
          </Text>
          <Text as={"span"} color={"base"} size={"small"} weight={"semibold"}>
            <Anchor
              css={{
                alignItems: "baseline",
                display: "flex",
                justifyContent: "space-between",
                width: "277px",
              }}
              href={`/distributors/application`}
            >
              {"Join our expanding network now "}
              <Icon size={"10px"} source={"nav-right"} />
            </Anchor>
          </Text>
        </div>
      </header>
    </div>
  );
};

export default Header;
