import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import useMutationObserver from "@rooks/use-mutation-observer";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import Text from "../Text";
import AccordionToggle from "./AccordionToggle";

interface Props {
  activeInitial?: boolean;
  children: ReactNode;
  subTitle?: string;
  title?: string;
}

const Accordion: FunctionComponent<Props> = ({
  activeInitial = false,
  children,
  subTitle,
  title,
}) => {
  const [active, setActive] = useState<boolean>();
  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  // Open the accordion if a country is selected
  if (
    activeInitial &&
    typeof active === "undefined" &&
    active !== activeInitial
  ) {
    setActive(activeInitial);
  }

  // We want to recalculate the height whenever the children change
  // Can't really do a pure css solution: https://css-tricks.com/using-css-transitions-auto-dimensions/
  const reflow = useCallback(() => {
    const contentHeight = contentRef.current?.scrollHeight;

    setHeight(active ? `${contentHeight}px` : "0px");
  }, [contentRef, active]);

  useEffect(() => {
    reflow();
  }, [active, children, reflow]);

  useMutationObserver(contentRef, reflow);

  const toggleAccordion = () => {
    setActive((prevActive) => !prevActive);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
      `}
    >
      <button
        css={css`
          align-items: baseline;
          background: none;
          border: none;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          outline: none;
          padding: 0;
          margin-bottom: 0;
          text-align: left;
        `}
        onClick={toggleAccordion}
      >
        <div
          css={css`
            max-width: calc(100% - 24px);
          `}
        >
          <Text
            as={"h4"}
            css={css`
              margin-bottom: 7px;
            `}
            responsive={true}
            size={"large"}
            weight={"semibold"}
          >
            {title}
          </Text>
          <Text
            as={"div"}
            color={"steelDarker"}
            size={"small"}
            weight={"regular"}
          >
            {subTitle}
          </Text>
        </div>
        <AccordionToggle active={active} size={"medium"} />
      </button>

      <div
        css={css`
          overflow: hidden;
          transition: max-height 0.3s cubic-bezier(0.46, 0.01, 0.92, 0.77);
          max-height: ${height};
          @media (max-width: ${mediaPhoneOnly}) {
            padding-right: 1.5rem;
          }
        `}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
