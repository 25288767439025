import { defaultLanguage, type LanguageToken } from "@10xdev/language-utils";

export default function coalesceLanguages<I extends Record<string, any>>(
  item: {
    [K in Exclude<LanguageToken, "ko">]: I;
  },
  targetLanguage: Exclude<LanguageToken, "ko">,
): I {
  return Object.entries(
    item[defaultLanguage as Exclude<LanguageToken, "ko">],
  ).reduce<I>((accumulator, [key, fallbackValue]) => {
    return {
      ...accumulator,
      [key]: item[targetLanguage][key] || fallbackValue,
    };
  }, {} as I);
}
