import type { BlogPost } from "@10xdev/types/models/BlogPost";
import {
  borderStandard,
  fontSizeLarge,
  layoutWidth878,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type {
  Hit,
  InfiniteHitsProvided,
  SearchState,
} from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-core";

import Intersection from "../Intersection";
import { useHitsFirstReceived, useInfiniteScroll } from "../Search/hooks";
import Stats from "../Search/Stats";
import BlogCard from "./BlogCard";
import BlogThumbnail from "./BlogThumbnail";
import useCurrentSearchPresent from "./hooks/useCurrentSearchPresent";

interface Props extends InfiniteHitsProvided<Hit<BlogPost>> {
  onHits: () => void;
  searchState: SearchState;
}

const BlogLayout: FunctionComponent<Props> = ({
  hits,
  onHits,
  hasMore,
  refineNext,
  searchState,
}: Props) => {
  useHitsFirstReceived(hits, onHits);

  const { searchPresent } = useCurrentSearchPresent(searchState);
  const showBlogGrid = !searchPresent;

  const { intersectionRef } = useInfiniteScroll(hasMore, refineNext);

  return (
    <div
      className={"BlogContent"}
      css={css`
        max-width: 1200px;
        margin: 0 auto;
        padding: 2rem 0;

        @media (max-width: ${layoutWidth878}) {
          padding: 0 1rem;
        }
      `}
    >
      {showBlogGrid ? (
        <ul
          className={"BlogGrid"}
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minMax(320px, 368px));
            column-gap: 3rem;
            row-gap: 4rem;
            list-style: none;
            margin: 0 0 4rem 0;
            padding: 1rem 0 6rem 0;
            border-bottom: ${borderStandard};
            justify-content: center;
          `}
        >
          {hits.slice(0, 9).map((hit) => {
            return (
              <li key={hit.objectID}>
                <BlogCard hit={hit} />
              </li>
            );
          })}
        </ul>
      ) : (
        <Stats
          css={css`
            font-size: ${fontSizeLarge};
          `}
        />
      )}

      <ul
        className={"BlogList"}
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minMax(320px, 568px));
          column-gap: 4rem;
          row-gap: 2.75rem;
          padding: 2rem 0 0 0;
          list-style: none;
          margin: 0;
          justify-content: center;
        `}
      >
        {showBlogGrid
          ? hits.slice(9).map((hit) => {
              return (
                <li key={hit.objectID}>
                  <BlogThumbnail hit={hit} />
                </li>
              );
            })
          : hits.map((hit) => {
              return (
                <li key={hit.objectID}>
                  <BlogThumbnail hit={hit} />
                </li>
              );
            })}
      </ul>

      {<Intersection ref={intersectionRef} />}
    </div>
  );
};

export default connectInfiniteHits<Props, Hit<BlogPost>>(BlogLayout);
