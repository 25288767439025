import { Field, Label } from "@headlessui/react";
import { FormConfig } from "./types";
import FormRow from "./FormRow";
import { useEffect, useRef } from "react";
import Text from "../Text";
import RequiredAsterisk from "./RequiredAsterisk";
import RadioGroup from "./RadioGroup";
import CheckboxGroup from "./CheckboxGroup";
import { useForm } from "./FormContext";
import { css } from "@emotion/react";
import Flex from "../Flex";
import Icon from "../Icon";
import Grid from "../Grid";
import { isFieldRequired } from "./utils";

interface FormBuilderProps<TValues> {
  config: FormConfig<TValues>;
}

const FieldRow = ({ children }: { children: React.ReactNode }) => (
  <Field>
    <FormRow>{children}</FormRow>
  </Field>
);

const FormBuilder = <TValues extends Record<string, any>>({
  config,
}: FormBuilderProps<TValues>) => {
  const { values, errors, touched, handleChange, handleSubmit } =
    useForm<TValues>();
  const previousValuesRef = useRef<TValues>(values);

  useEffect(() => {
    config.fields.forEach((field) => {
      if (field.transformValue) {
        const newValue = field.transformValue(
          values,
          previousValuesRef.current,
        );
        if (newValue !== values[field.name]) {
          handleChange(field.name, newValue);
        }
      }
    });
    previousValuesRef.current = values;
  }, [config.fields, handleChange, values]);

  return (
    <form
      css={css`
        display: grid;
        gap: 1rem;
      `}
      id={config.id}
      onSubmit={handleSubmit}
    >
      {config.fields.map((field) => {
        const isVisible = field.shouldShow ? field.shouldShow(values) : true;
        if (!isVisible) {
          return null;
        }

        const isDisabled = field.shouldEnable
          ? !field.shouldEnable(values)
          : false;

        const error = errors[field.name];
        const isTouched = touched[field.name];

        const fieldOptions =
          typeof field.options === "function"
            ? field.options(values)
            : field.options;

        return (
          <Grid gap="0.5rem" key={String(field.name)}>
            <FieldRow>
              <Label>
                {typeof field.label === "function" ? (
                  field.label(values, {
                    required: isFieldRequired(field, values),
                    disabled: isDisabled,
                    error,
                  })
                ) : (
                  <Text
                    as="div"
                    color={isDisabled ? "steelMedium" : "steelDarkest"}
                    size="xsmall"
                    weight="medium"
                  >
                    <span>{field.label}</span>
                    {isFieldRequired(field, values) ? (
                      <RequiredAsterisk disabled={isDisabled} />
                    ) : null}
                  </Text>
                )}
              </Label>
              {field.type === "radio" && (
                <RadioGroup
                  options={fieldOptions || []}
                  selectedValue={values[field.name]}
                  onChange={(value) => {
                    handleChange(field.name, value);
                  }}
                  name={String(field.name)}
                  disabled={isDisabled}
                />
              )}
              {field.type === "checkbox" && (
                <CheckboxGroup
                  options={fieldOptions || []}
                  values={values[field.name]}
                  onChange={(selectedValues) => {
                    handleChange(field.name, selectedValues);
                  }}
                  name={String(field.name)}
                  disabled={isDisabled}
                />
              )}
            </FieldRow>
            {error && isTouched && (
              <FormRow>
                <div />
                <Flex alignItems="center" gap="0.5rem">
                  <Icon color="red" source="warning" size="14px" />
                  <Text as="div" color="red" size="xsmall">
                    {error}
                  </Text>
                </Flex>
              </FormRow>
            )}
          </Grid>
        );
      })}
    </form>
  );
};

export default FormBuilder;
