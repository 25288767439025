import { css } from "@emotion/react";
import { mediaPhoneOnly } from "@10xdev/design-tokens";

import Grid from "../../../Grid";
import Image from "../../../Image";
import Links from "../../../Links";
import Text from "../../../Text";
import type { SectionImage, SectionLink } from "../types";
export interface Props {
  description: string;
  image: SectionImage;
  links?: SectionLink[];
  title?: string;
}

const CardB = ({ description, image, links, title }: Props) => {
  return (
    <Grid
      alignContent={"start"}
      css={css`
        flex: 1 1 0;
      `}
      gap={{ base: "0.5rem", tabletPortrait: "1rem" }}
      gridTemplateColumns={{ base: "4fr 7fr", tabletPortrait: "1fr" }}
    >
      {image && image.srcSmall ? (
        <Image
          alt={image.alt}
          css={css`
            display: block;
            @media (min-width: ${mediaPhoneOnly}) {
              display: none;
            }
          `}
          src={image.srcSmall}
        />
      ) : null}
      {image ? (
        <Image
          alt={image.alt}
          css={css`
            display: ${image.srcSmall ? "none" : "block"};
            @media (min-width: ${mediaPhoneOnly}) {
              display: block;
            }
          `}
          src={image.src}
        />
      ) : null}
      <Grid gap={{ base: "0.5rem", tabletPortrait: "1rem" }}>
        {title && (
          <Text as={"h4"} size={"large"} weight={"semibold"}>
            {title}
          </Text>
        )}
        <Text as={"p"} color={"midgray"} size={"medium"}>
          {description}
        </Text>
        {links?.length ? <Links links={links} /> : null}
      </Grid>
    </Grid>
  );
};

export default CardB;
