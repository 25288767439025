import { borderRadiusMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../Anchor";
import Text from "../Text";

interface Props {
  board?: {
    __typename?: "Person";
    avatar: string | null;
    foreName: string | null;
    initials: string | null;
    institution: string | null;
    lastName: string | null;
    position: string | null;
    qualifications: string | null;
    slug: string;
  }[];
}
const Board: FunctionComponent<Props> = ({ board }) => {
  return (
    <ul
      css={css`
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        list-style: none;
        margin: 0;
        overflow: auto;
        padding: 0 0 2rem;
        width: 100%;
      `}
    >
      {board?.map((member) => {
        const { foreName, lastName, slug } = member;
        return (
          <li
            css={css`
              border-radius: ${borderRadiusMedium};
              border: solid 1px rgba(68, 89, 121, 0.06);
              box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
              box-sizing: border-box;
              min-width: 240px;
              transition: box-shadow 0.3s ease;
              width: 100%;

              :hover {
                box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16);
                cursor: pointer;
              }
            `}
            key={slug}
          >
            <Text
              as={"div"}
              css={css`
                padding: 1.25rem 1.5rem;
              `}
              size={"medium"}
              weight={"medium"}
            >
              <Anchor
                aria-label={`${foreName} ${lastName} board of director profile`}
                css={{ display: "block" }}
                href={`/company/board/${slug}`}
                key={slug}
              >
                {`${foreName} ${lastName}`}
              </Anchor>
            </Text>
          </li>
        );
      })}
    </ul>
  );
};

export default Board;
