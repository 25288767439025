import {
  borderRadiusMedium,
  borderStandard,
  colorWhite,
} from "@10xdev/design-tokens";
import type { AlgoliaServiceProvider } from "@10xdev/types/models/Algolia/ServiceProvider";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { Hit, HitsProvided } from "react-instantsearch-core";
import { connectHits } from "react-instantsearch-core";

import { useHitsFirstReceived } from "../Search/hooks";
import Accordion from "./Accordion";
import ProviderInfo from "./ProviderInfo";
import { getSubtitle } from "./utils";

interface Props extends HitsProvided<Hit<AlgoliaServiceProvider>> {
  onHits: () => void;
}

const List: FunctionComponent<Props> = ({ hits, onHits }: Props) => {
  useHitsFirstReceived(hits, onHits);

  return (
    <ul
      css={css`
        list-style: none;
        padding: 0;
        margin: 0 0 3.25rem 0;
        border: ${borderStandard};
        background: ${colorWhite};
        border-radius: ${borderRadiusMedium};
      `}
    >
      {hits.map((hit) => {
        const { altLocationLabel, city, country, name } = hit;
        const subtitle = getSubtitle({ altLocationLabel, city, country });

        return (
          <li
            css={css`
              border-top: ${borderStandard};
              padding: 1rem 1rem 1rem 2rem;

              :first-of-type {
                border-top: none;
              }
            `}
            key={`${name}-${city}`}
          >
            <Accordion subTitle={subtitle} title={name}>
              <ProviderInfo hit={hit} />
            </Accordion>
          </li>
        );
      })}
    </ul>
  );
};

export default connectHits<Props, Hit<AlgoliaServiceProvider>>(List);
