import type { BlogPost } from "@10xdev/types/models/BlogPost";
import { colorBlueDark, mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import type { Hit } from "react-instantsearch-core";

import Anchor from "../Anchor";
import Image from "../Image";
import Text from "../Text";
import { formatDateAndCategory, transformImageSize } from "./utils";

interface Props {
  hit: Hit<BlogPost>;
}

const BlogThumbnail: FunctionComponent<Props> = ({ hit }) => {
  const { socialImage, researchAreas, timestamp, author, title, slug } = hit;
  const blogDetailCopy = formatDateAndCategory(timestamp, researchAreas);
  const resizedImage = transformImageSize(socialImage, "w_186");
  return (
    <Anchor color={"base"} hoverColor={colorBlueDark} href={`/blog/${slug}`}>
      <div
        className={"BlogThumbnail"}
        css={css`
          display: flex;
          align-items: flex-start;

          @media (max-width: ${mediaPhoneOnly}) {
            flex-direction: column;
          }
        `}
      >
        <div
          className={"ImageContainer"}
          css={css`
            width: 184px;
            height: 104px;
            overflow: hidden;
            margin-right: 1.5rem;
            display: block;
            margin-bottom: 1.5rem;
            flex-shrink: 0;

            @media (max-width: ${mediaPhoneOnly}) {
              width: 100%;
              height: 100%;
            }
          `}
        >
          <Image alt={title} src={resizedImage} />
        </div>
        <div>
          <Text
            as={"div"}
            color={"blueMedium"}
            css={css`
              margin-bottom: 0.25rem;
              text-transform: uppercase;
            `}
            size={"xsmall"}
            weight={"medium"}
          >
            {blogDetailCopy}
          </Text>
          <Text
            as={"div"}
            color={"inherit"}
            css={css`
              margin-bottom: 0.25rem;
            `}
            size={"small"}
            weight={"semibold"}
          >
            {title}
          </Text>

          <Text
            as={"div"}
            color={"steelDarker"}
            size={"xsmall"}
            weight={"regular"}
          >
            {`${author.foreName} ${author.lastName}`}
          </Text>
        </div>
      </div>
    </Anchor>
  );
};

export default BlogThumbnail;
