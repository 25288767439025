import type { OptionalToNullable } from "@10xdev/netlify-cms-graphql/utils/types";
import { colorSteelLighter, mediaTabletLandscape } from "@10xdev/design-tokens";
import type { Location } from "@10xdev/types/models/Location";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";

import Button from "../Button";
import Heading from "../Heading";
import { SpanifiedText } from "../SpanifiedText";
import Text from "../Text";

interface Props {
  action?: {
    label?: string | null;
    url?: string | null;
  } | null;
  locations?: OptionalToNullable<Location>[] | null;
  title?: string | null;
}

const columnCss = css`
  margin-right: 0;

  :first-of-type {
    margin-bottom: 2rem;
  }

  @media (min-width: ${mediaTabletLandscape}) {
    :first-of-type {
      flex: 2;
      margin-right: 10%;
    }

    :last-of-type {
      display: grid;
      flex: 7;
      gap: 2.5rem 7.5rem;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

const LocationCard: FunctionComponent<Props> = ({
  action,
  locations,
  title,
}) => {
  const router = useRouter();

  const handleClick = (url: string) => router.push(url);

  return (
    <li
      css={css`
        border-top: solid 1px ${colorSteelLighter};
        padding: 2rem 0;

        @media (min-width: ${mediaTabletLandscape}) {
          display: flex;
          flex-wrap: nowrap;
          padding: 3rem 0;
        }
      `}
    >
      <div css={columnCss}>
        {title ? (
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 1.5rem;
            `}
            size={"xlarge"}
          >
            {title}
          </Heading>
        ) : null}

        {action ? (
          <Button
            background={"white"}
            border={true}
            color={"blue"}
            css={{
              width: "168px",
            }}
            onClick={() => {
              if (action.url) {
                handleClick(action.url);
              }
            }}
          >
            <Text as={"div"} color={"inherit"} size={"small"} weight={"medium"}>
              {action.label}
            </Text>
          </Button>
        ) : null}
      </div>

      <div css={columnCss}>
        {locations?.length
          ? locations.map((location) => {
              const { city, country, formattedAddress, name, phone, state } =
                location;
              return (
                <div
                  css={css`
                    margin-bottom: 2rem;
                  `}
                  key={formattedAddress}
                >
                  {city ? (
                    <Text
                      as={"div"}
                      css={css`
                        margin-bottom: 1rem;
                      `}
                      size={"large"}
                    >
                      {`${city}${state || country ? "," : ""} ${
                        state || country || ""
                      }`}
                    </Text>
                  ) : null}

                  {name ? (
                    <Text
                      as={"div"}
                      css={css`
                        margin-bottom: 0.5rem;
                      `}
                      size={"medium"}
                      weight={"semibold"}
                    >
                      {name}
                    </Text>
                  ) : null}

                  {formattedAddress ? (
                    <Text
                      as={"div"}
                      css={css`
                        margin-bottom: 0.5rem;
                      `}
                      size={"medium"}
                    >
                      {formattedAddress}
                    </Text>
                  ) : null}

                  {phone?.length
                    ? phone.map((number) => {
                        /*
                          This is necessary to prevent a Google AdWords script that we do not control from adding addtional `+` symbols
                          at the start of the phone number every time the page comes into focus.
                        */
                        return (
                          <SpanifiedText
                            as={"div"}
                            css={css`
                              margin-bottom: 0.5rem;
                            `}
                            key={number}
                            size={"medium"}
                          >
                            {number}
                          </SpanifiedText>
                        );
                      })
                    : null}
                </div>
              );
            })
          : null}
      </div>
    </li>
  );
};

export default LocationCard;
