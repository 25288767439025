import { mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { debounce } from "lodash-es";
import type { ReactNode, RefObject } from "react";
import { Children, useState } from "react";

interface Props {
  aspectRatio: number;
  children: ReactNode;
  gap?: number;
  itemHeight: number;
  itemsPerScroll?: number;
  carouselRef: RefObject<HTMLElement>;
}

const useCarousel = ({
  aspectRatio = 1.1,
  children,
  gap = 16,
  itemsPerScroll = 3,
  itemHeight = 312,
  carouselRef,
}: Props) => {
  const itemWidth = Math.round(itemHeight * aspectRatio);
  const viewWidth = (itemWidth + gap) * itemsPerScroll;
  const carouselWidth = (itemWidth + gap) * Children.toArray(children).length;

  const [offSet, setOffSet] = useState(0);

  const handlePreviousClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft - viewWidth,
        behavior: "smooth",
      });
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.scroll({
        left: carouselRef.current.scrollLeft + viewWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScrollDebounced = debounce((event) => {
    setOffSet((event.target as HTMLDivElement).scrollLeft);
  }, 100);

  const carouselCss = css`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-bottom: 2rem;
    width: 100%;
    gap: ${gap}px;

    @media (min-width: ${mediaTabletLandscape}) {
      margin-bottom: 1.5rem;
      box-sizing: border-box;
      padding-bottom: 0;
      overscroll-behavior-x: contain;
      padding-bottom: 0;

      > :last-child {
        margin-right: 120px;
      }
    }
  `;

  const buttonContainerCss = css`
    display: none;

    @media (min-width: ${mediaTabletLandscape}) {
      display: flex;
      justify-content: flex-end;
    }
  `;

  return {
    buttonContainerCss,
    carouselCss,
    carouselWidth,
    handleNextClick,
    handlePreviousClick,
    handleScrollDebounced,
    itemWidth,
    offSet,
    viewWidth,
  };
};

export default useCarousel;
