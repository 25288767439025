import type { AlgoliaVideo } from "@10xdev/types/models/Algolia/Video";
import { FACETS } from "@10xdev/types/models/Video";
import { format } from "date-fns";
import ConnectedStats from "../AlgoliaConnected/ConnectedStats";
import { MultiSelectFilterProp } from "../AlgoliaConnected/Filter/types";
import { ColumnType } from "../AlgoliaConnected/Table";
import Flex from "../Flex";
import Text from "../Text";
import TitleCell from "./TitleCell";
import { getFormattedDuration } from "./utils";

export const VIDEO_FACETS = [
  {
    attribute: FACETS.categoryLabel,
    label: "Category",
  },
  {
    attribute: FACETS.productName,
    label: "Product",
  },
  {
    attribute: FACETS.researchAreas,
    label: "Area of Interest",
  },
  {
    attribute: FACETS.language,
    label: "Language",
  },
];

export const HEADER_HEIGHT = 325;
export const ASPECT_RATIO = 0.5625;
export const TITLE_COL_WIDTH = 416;
export const THUMBNAIL_WIDTH = 140;
export const THUMBNAIL_HEIGHT = Math.round(THUMBNAIL_WIDTH * ASPECT_RATIO);

export const TOP_SEARCHES = [
  "GEM-X",
  "HD",
  "Xenium",
  "Flex",
  "Loupe",
  "FFPE",
  "Nuclei Isolation",
  "CRISPR",
];

export const DISPLAYED_CATEGORY_FILTERS = [
  "How-To Video",
  "Webinar",
  "Seminar",
  "Training Module",
];

export const DISPLAYED_PRODUCT_FILTERS = [
  "Universal 3' Gene Expression",
  "Universal 5' Gene Expression",
  "Flex Gene Expression",
  "Epi Multiome ATAC + Gene Expression",
  "Epi ATAC",
  "Single Cell CRISPR Screening",
  "HD Spatial Gene Expression",
  "Spatial Gene Expression",
  "CytAssist Spatial Gene Expression",
  "CytAssist Spatial Gene and Protein Expression",
  "In Situ Gene Expression",
];

export const DISPLAYED_LANGUAGE_FILTERS = ["English", "中文", "日本語"];

export const videosFilterGroups: MultiSelectFilterProp[] = [
  {
    id: "category.label",
    label: "Category",
    type: "multiple",
  },
  {
    id: "products.name",
    label: "Products",
    type: "multiple",
  },
  {
    id: "language",
    label: "Language",
    type: "multiple",
  },
];

const renderNA = () => (
  <Text as={"span"} color={"steelMedium"} size={"small"} weight={"regular"}>
    {"N/A"}
  </Text>
);

export const tableColumns: ColumnType<AlgoliaVideo>[] = [
  {
    displayCss: `@media (min-width: 1303px) { width: 480px; }`,
    key: "title",
    renderTitle: (
      <Flex gap={".5rem"}>
        <Text as={"span"} size={"xsmall"} weight={"semibold"}>
          {"Videos"}
        </Text>
        <ConnectedStats indexName={"videos"} />
      </Flex>
    ),
    render: (title, record) => {
      return <TitleCell title={title} record={record} />;
    },
    width: "380px",
  },
  {
    displayCss: `@media (max-width: 1100px) { display: none; } @media (min-width: 1303px) { width: auto; }`,
    key: "products",
    renderTitle: "Product",
    render: (products, _) => {
      if (!products?.length) {
        return renderNA();
      }

      return products.map((product) => product.name).join(", ");
    },
    width: "100px",
  },
  {
    displayCss: `@media (max-width: 1100px) { display: none; } @media (min-width: 1303px) { width: auto; }`,
    key: "category",
    renderTitle: "Category",
    render: (category, _) => {
      if (!category) {
        return renderNA();
      }
      return category.label;
    },
    width: "90px",
  },
  {
    displayCss: `@media (max-width: 1100px) { display: none; } @media (min-width: 1303px) { width: auto; }`,
    key: "duration",
    renderTitle: "Duration",
    render: (duration, _) => {
      if (!duration) {
        return renderNA();
      }
      return getFormattedDuration(duration);
    },
    width: "90px",
  },
  {
    displayCss: `@media (max-width: 1100px) { display: none; } @media (min-width: 1303px) { width: auto; }`,
    key: "created",
    renderTitle: "Date",
    render: (created, _) => {
      if (!created) {
        return renderNA();
      }
      return format(new Date(created), "MMM d, yyyy");
    },
    width: "80px",
  },
];
