import { FormattedWistiaMedia } from "./Wistia/Media";
import { GetAllVideosQuery } from "../__generated__/graphql-types";

export type Video = GetAllVideosQuery["videos"][number] &
  Pick<FormattedWistiaMedia, "duration" | "thumbnail">;

export enum FACETS {
  categoryLabel = "category.label",
  language = "language",
  productName = "products.name",
  researchAreas = "researchAreas",
  timestamp = "timestamp",
}
