import { colorBlueDark } from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import Text from "../../Text";

interface Props {
  link: {
    label?: string | null;
    url?: string | null;
  };
}

const NavLink = ({ link }: Props) => {
  return (
    <Anchor
      color={"blue"}
      css={css`
        align-items: baseline;
        display: flex;
        gap: 0.5rem;
        width: fit-content;
      `}
      hoverColor={colorBlueDark}
      href={link.url ?? ""}
    >
      <Text as={"span"} color={"inherit"} size={"small"} weight={"medium"}>
        {link.label}
      </Text>
      <Icon size={"8px"} source={"nav-right"} />
    </Anchor>
  );
};

export default NavLink;
