import type { BlogPost } from "@10xdev/types/models/BlogPost";
import {
  colorSteelDarker,
  colorSteelDarkest,
  layoutWidth1200,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Banner from "../../Blocks/Masthead/Banner";
import FeaturedArticleInfo from "./FeaturedArticleInfo";
import Heading from "./Heading";
import SocialLinks from "./SocialLinks";

interface Props {
  featuredBlogPost: BlogPost;
}

const Masthead: FunctionComponent<Props> = ({ featuredBlogPost }) => {
  return (
    <div
      className={"Masthead"}
      css={css`
        align-items: center;
        background: ${colorSteelDarkest};
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0 1rem;
        padding-bottom: 4.5rem;
        width: 100%;
      `}
    >
      <Banner mode={"dark"} />

      {featuredBlogPost ? (
        <>
          <div
            className={"Content"}
            css={css`
              align-items: center;
              border-bottom: 1px solid ${colorSteelDarker};
              display: flex;
              justify-content: space-between;
              height: 100%;
              margin-bottom: 4rem;
              max-width: ${layoutWidth1200};
              padding: 3rem 0 1rem;
              width: 100%;
            `}
          >
            <Heading />
            <SocialLinks />
          </div>
          <FeaturedArticleInfo featuredBlogPost={featuredBlogPost} />
        </>
      ) : null}
    </div>
  );
};

export default Masthead;
